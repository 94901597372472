//Adicionar Sector

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiMapPin } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

export interface NewSectorData {
  id: number;
  title: string;
  isActive: Number;
}

interface AddSectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddSector: (newSector: NewSectorData) => Promise<void>;
}

const AddSectorModal: React.FC<AddSectorModalProps> = ({
  isOpen,
  onClose,
  handleAddSector,
}) => {
  const formRef = useRef<FormHandles>(null);
  // const [selectedAgendamento, setSelectedAgendamento] = useState('');
  const [isActive, setIsActive] = useState<number>(1);

  async function handleSubmit(data: NewSectorData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        title: Yup.string().required('Titulo obrigatorio'),
        isActive: Yup.number().required('Ativo obrigatorio'), // Altere para number
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      console.log('Valores HANDLESUBMIT######', data); // Adicione este console.log

      await handleAddSector(data);
      onClose();
    } catch (err) {
      console.log('Valores HANDLESUBMIT---------------', data); // Adicione este console.log

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      // Lógica para exibir mensagens de erro no toast caso necessário
      // quero exibir uma mensagem de erro
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h1>Adicionar Setor</h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  name="title"
                  icon={FiMapPin}
                  placeholder="Nome do Setor"
                />

                <div>
                  <label>Status:</label>
                  <select
                    name="isActive"
                    value={isActive === undefined ? '1' : isActive.toString()}
                    onChange={e => {
                      const value = Number(e.target.value);
                      setIsActive(value);
                    }}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>
                <Input name="isActive" type="hidden" value={isActive} />

                <Button type="submit">Adicionar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};
export default AddSectorModal;
