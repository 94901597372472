import { format, parseISO } from 'date-fns';
// import Accordion from 'react-bootstrap/Accordion';
// import AlwaysOpenExample from './Accordion';

import pt from 'date-fns/locale/pt-BR';
import React, { useEffect, useState, useRef } from 'react';
import { FiCalendar, FiClock, FiMapPin } from 'react-icons/fi';
import { AiFillPhone, AiOutlineWhatsApp } from 'react-icons/ai';

import { Link, useHistory } from 'react-router-dom';
import { CountBadge } from './styles';
import Accordion from './Accordion';

import {
  holidays,
  libraryVacation,
  mumpsMigration,
} from '../../config/holiday';
import api from '../../services/api';

import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Footer from '../../components/Footer';
import Header from '../../components/HeaderLanding';

import homeImage from '../../assets/hero.png';
import homeImage2 from '../../assets/image2.png';
import escolher_local from '../../assets/escolher_local1.png';
import {
  AvailableTimes,
  AvailableTimesContainer,
  BoxContainer,
  BoxH2,
  Container,
  Content,
  MobileNavigation,
  Picker,
  PickerContainer,
  Subtitle,
  Times,
  Section,
  StyledSectorInput,
  FooterFixed,
  Section2,
  ContactButtonsContainer,
  WhatsAppButton,
  PhoneButton,
} from './styles';
// import Welcome from './Welcome';
// import getValidationErrors from '../../utils/getValidationErrors';
// import * as Yup from 'yup';
// import Message from './Message';
// import scrollreveal from 'scrollreveal';

import SectorInput from '../components/SectorSelectInput';
// import Accordion from '../components/Accordion';
interface AvailableTime {
  time: string;
  value: Date;
  available: boolean;
  appointmentCount: number;
  available_1: boolean;
  appointmentCount_1: number;
}

interface Faq {
  question: string;
  answer: string;
}

interface Config {
  id: number;
  image: string;
  image_red: string;
  local: string;
  abreviation: string;
  phone: string;
  whatsapp: string;
  email: string;
  andress: string;
  imageFile?: File; // Campo opcional para o arquivo da imagem
  imageRedFile?: File;
}

const Landing: React.FC = () => {
  const [selectedSector, setSelectedSector] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>([]);
  const [faq, setFaq] = useState<Faq[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState({
    time: new Date(),
    parsedTime: '',
  });
  const [hourIsSelected, setHourIsSelected] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const { user } = useAuth();
  const [configs, setConfigs] = useState<Config[]>([]);
  const [
    settingsPersonalized,
    setSettingsPersonalized,
  ] = useState<Config | null>(null);

  useEffect(() => {
    async function loadConfigs() {
      try {
        const response = await api.get('/config');
        setConfigs(response.data);

        const configToEdit = response.data.find(
          (config: Config) => config.id === 1,
        );
        if (configToEdit) {
          setSettingsPersonalized(configToEdit);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Dados de personalização nao carregados',
          description: 'Dados de personalização nao carregados',
        });
      }
    }
    loadConfigs();
  }, [addToast]);

  useEffect(() => {
    async function loadFaq(): Promise<void> {
      const response = await api.get('faq');
      const faq: Faq[] = response.data.map((faq: Faq) => ({
        ...faq,
      }));
      setFaq(faq);
    }
    loadFaq();
  }, []);

  useEffect(() => {
    setSelectedHour({
      time: new Date(),
      parsedTime: '',
    });
    setHourIsSelected(false);

    async function loadAvailableTimes(): Promise<void> {
      const response = await api.get('availables', {
        params: {
          date: selectedDate.getTime(),
          sector_id: selectedSector,
        },
      });
      const available: AvailableTime[] = response.data.map(
        (time: AvailableTime) => ({
          ...time,
          available_1: time.appointmentCount > 1,
          appointmentCount_1: time.appointmentCount - 1,
        }),
      );
      setAvailableTimes(available);
    }

    loadAvailableTimes();
  }, [selectedDate, selectedSector]);

  function handleSelectSector(e: React.ChangeEvent<HTMLSelectElement>): void {
    setSelectedSector(Number(e.target.value));

    setSelectedHour({
      time: new Date(),
      parsedTime: '',
    });
    setHourIsSelected(false);
  }

  const [toggle, setToggle] = useState<boolean>(false);
  const [heightEl, setHeightEl] = useState<string | undefined>(undefined);

  const refHeight = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refHeight.current) {
      setHeightEl(`${refHeight.current.scrollHeight}px`);
    }
  }, []);

  const toggleState = () => {
    setToggle(!toggle);
  };

  // useEffect(() => {
  //   const sr = scrollreveal({
  //     origin: 'top',
  //     distance: '80px',
  //     duration: 5000,
  //     reset: true,
  //   });

  //   sr.reveal(`Header,BoxContainer, Content,Times, Section2,FooterFixed`, {
  //     opacity: 0,
  //     interval: 300,
  //   });
  // }, []);
  return (
    <Container>
      <Header />
      {/* ----------------------------------------------- */}
      <Content>
        <MobileNavigation>
          <h1>
            <Link to="signin">LOGIN</Link>
          </h1>
          <h1>
            <Link to="signup">CADASATRE-SE</Link>
          </h1>
        </MobileNavigation>

        <AvailableTimesContainer>
          <Section id="hero">
            <div className="background">
              <img src={homeImage} alt="" />
            </div>
            <div className="content">
              <div className="title">
                <h1>AGENDAMENTO</h1>
                <h5>Atendimento Presencial</h5>
              </div>
              <div className="search">
                <div className="container">
                  <label htmlFor="">
                    <FiMapPin size={25} color="#990000" />
                    Local
                  </label>

                  <StyledSectorInput>
                    <SectorInput
                      name="sectorSelect"
                      onChange={handleSelectSector}
                    />
                  </StyledSectorInput>
                </div>

                <div className="container">
                  <label htmlFor="">
                    {' '}
                    <FiCalendar size={25} color="#990000" />
                    Data
                  </label>
                  <StyledSectorInput>
                    <PickerContainer>
                      <Picker
                        locale={pt}
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={selectedDate}
                        excludeDates={
                          selectedSector === 1
                            ? holidays.concat(mumpsMigration)
                            : holidays.concat(libraryVacation)
                        }
                        onChange={(date: Date) => setSelectedDate(date)}
                      />
                    </PickerContainer>
                  </StyledSectorInput>
                </div>
                {/* <button>Explore Now</button> */}
              </div>
              <ContactButtonsContainer>
                <WhatsAppButton href="https://wa.me/5571999862572">
                  <AiOutlineWhatsApp size={20} />
                  {settingsPersonalized?.whatsapp}
                </WhatsAppButton>
                <PhoneButton href="tel:08001502525">
                  <AiFillPhone size={15} style={{ margin: '0 8px' }} />
                  {settingsPersonalized?.phone}{' '}
                  <AiFillPhone size={15} style={{ margin: '0 8px' }} /> Sobral
                  (88) 99323-0038
                  <AiFillPhone size={15} style={{ margin: '0 8px' }} />
                  Juazeiro do Norte (88) 99332-2120
                </PhoneButton>
              </ContactButtonsContainer>
            </div>
          </Section>

          {/* -------------------------------------------------------------------- */}
          <BoxContainer>
            <BoxH2>
              <FiClock size={25} />
              <strong> Selecione um Horário</strong>
            </BoxH2>
          </BoxContainer>

          {selectedSector === null ? (
            // Se nenhum setor estiver selecionado, renderize a imagem
            <div className="no-sector-selected">
              <img src={escolher_local} alt="Selecione um setor" />
            </div>
          ) : (
            <Times columns={4}>
              {availableTimes.map(availableTime => (
                <AvailableTimes
                  enabled={availableTime.available}
                  key={availableTime.time}
                  // onClick={() => handleSelectHour(availableTime.value)}
                  className={
                    selectedHour.parsedTime.includes(`${availableTime.time}`)
                      ? 'selected'
                      : ''
                  }
                  // onClick={() => handleSelectHour(availableTime.value)} // Chama handleSelectHour para selecionar o horário e abrir o modal
                  // nesse on click eu quero ir para pagina / rota de signin
                  onClick={() => history.push('/signin')}
                >
                  <span>{availableTime.time}</span>

                  <>
                    {availableTime.available &&
                      availableTime.appointmentCount > 0 && (
                        <CountBadge>
                          {availableTime.appointmentCount}
                        </CountBadge>
                      )}
                  </>
                </AvailableTimes>
              ))}
            </Times>
          )}

          <Subtitle>
            <div>
              <div className="first-square" />
              Horários Disponíveis
            </div>
            <div>
              <div className="second-square" />
              Horários Indisponíveis
            </div>
            <div>
              <div className="third-circle" />
              Atendimentos Disponíveis
            </div>
          </Subtitle>

          {/* ------------------------------------------------------- */}
          <Section2>
            <div className="background">
              <img src={homeImage2} alt="" />
            </div>
            <div className="content">
              <div className="title">
                <h1>Perguntas Frequentes</h1>{' '}
              </div>
            </div>
          </Section2>
          <Accordion
            faqs={faq.map(faqItem => ({
              question: faqItem.question,
              answer: faqItem.answer,
            }))}
          />
        </AvailableTimesContainer>
      </Content>
      {/* ------------------------------------------------ */}
      <FooterFixed>
        <Footer />
      </FooterFixed>
    </Container>
  );
};

export default Landing;
