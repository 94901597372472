// criar pagina de schedules
import React, { useEffect, useState } from 'react';
import { FiFrown, FiTrash2, FiEdit } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import Header from '../../../components/Header';
import AddFaqModal, { NewFaqData } from './Modal';
import EditFaqModal, { EditFaqsData } from './Modal/EditFaqModal';
import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';
import { tr } from 'date-fns/locale';

interface Faq {
  id: number;
  question: string;
  answer: string;
}

const Faq: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState<EditFaqsData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadFaq(): Promise<void> {
      try {
        const response = await api.get('faq');
        setFaqs(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os dados',
          description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    }

    loadFaq();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  function handleOpenModal(): void {
    setShowModal(true);
  }

  async function handleCloseModal(): Promise<void> {
    setShowModal(false);

    try {
      const response = await api.get('/faq');
      setFaqs(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os dados',
        description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
      });
    }
  }

  async function handleOpenEditModal(faq: Faq): Promise<void> {
    const editedFaq: NewFaqData = {
      id: faq.id,
      question: faq.question,
      answer: faq.answer,
    };
    setSelectedFaq(editedFaq);
    setShowEditModal(true);
  }

  async function handleCloseEditModal(): Promise<void> {
    setShowEditModal(false);
    setSelectedFaq(null);
  }

  async function handleAddFaq(newFaq: NewFaqData): Promise<void> {
    try {
      await api.post('/faq', newFaq);
      // setFaqs([...faqs, newFaq]);
      addToast({
        type: 'success',
        title: 'Pergunta adicionada com sucesso!',
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar a pergunta',
        description:
          'Ocorreu um erro ao adicionar a pergunta, tente novamente.',
      });
    }
  }

  async function handleEditFaq(editedFaq: EditFaqsData): Promise<void> {
    try {
      await api.put(`/faq/${editedFaq.id}`, editedFaq);
      const response = await api.get('/faq');
      const allFaqs = response.data;
      if (isMounted) {
        setFaqs(allFaqs);
      }
      addToast({
        type: 'success',
        title: 'Faq atualizada com sucesso!',
      });
      setShowEditModal(false);
      setSelectedFaq(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o Faq',
        description:
          'Ocorreu um erro ao atualizar a pergunta, tente novamente.',
      });
    }
  }

  async function handleDeleteFaq(id: number): Promise<void> {
    try {
      const confirmDelete = window.confirm(
        'Tem certeza que deseja deletar este setor?',
      );
      if (!confirmDelete) {
        return;
      }
      await api.delete(`/faq/${id}`);
      const response = await api.get('/faq');
      const allFaqs = response.data;
      if (isMounted) {
        setFaqs(allFaqs);
      }
      addToast({
        type: 'success',
        title: 'Pergunta deletada!',
        description: 'Pergunta deletada com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar pergunta',
        description: 'Ocorreu um erro ao deletar a pergunta, tente novamente.',
      });
    }
  }

  // Redirecionamento para outra página caso o usuário não seja um provedor
  useEffect(() => {
    if (user.isProvider === 0) {
      history.push('/user/dashboard');
    }
  }, [user.isProvider, history]);

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Content>
            <AddButton onClick={handleOpenModal}>
              <strong>Adicionar</strong>
            </AddButton>

            {faqs.length > 0 ? (
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      <th>Pergunta</th>
                      <th>Resposta</th>
                      <th>Editar</th>
                      <th>Deletar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faqs.map(faq => (
                      <tr key={faq.id}>
                        <td>{faq.question}</td>
                        <td>{faq.answer}</td>
                        <td>
                          <IconButton onClick={() => handleOpenEditModal(faq)}>
                            <FiEdit size={20} />
                          </IconButton>
                        </td>
                        <td>
                          <IconButton onClick={() => handleDeleteFaq(faq.id)}>
                            <FiTrash2 size={20} />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            ) : (
              <EmptyList>
                <FiFrown size={70} color="#fff" />
                <strong>Nenhuma pergunta</strong>
              </EmptyList>
            )}
          </Content>
        )}
      </Container>
      {showModal && (
        <AddFaqModal
          isOpen={showModal}
          onClose={handleCloseModal}
          handleAddFaq={handleAddFaq}
        />
      )}

      {showEditModal && selectedFaq && (
        <EditFaqModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          handleEditFaqs={handleEditFaq}
          faqs={selectedFaq}
        />
      )}
    </>
  );
};

export default Faq;
