import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiArchive, FiFileText, FiMapPin } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';
import SectorInput from '../../Schedules/Modal/SectorInput';
import Sectors from '../../Sectors';

export interface NewSubjectData {
  id: number;
  title: string;
  description: string;
  sector_id: number;
  isActive: number;
}

interface AddSubjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddSubject: (newSubject: NewSubjectData) => Promise<void>;
  // sector deve seror do tipo sectors
  sectors: any[];
  //sectors: string[]; // Adicione a propriedade de setores
}

const AddSubjectModal: React.FC<AddSubjectModalProps> = ({
  isOpen,
  onClose,
  handleAddSubject,
  sectors, // Receba os setores como propriedade
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState<number>(1);
  // console.log("Valores em sectors:", sectors); // Adicione este console.log

  async function handleSubmit(data: NewSubjectData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        title: Yup.string().required('Nome obrigatório'),
        description: Yup.string().required('Descrição obrigatória'),
        sector_id: Yup.number().required('Setor obrigatório'),
      });

      data.isActive = isActive === undefined ? 0 : isActive;
      console.log('Valores HANDLESUBMIT%%%%%%%%%%%%%%', data); // Adicione este console.log

      await schema.validate(data, {
        abortEarly: false,
      });
      console.log('Valores HANDLESUBMIT%%%%%%%%%%%%%%', data); // Adicione este console.log

      await handleAddSubject(data);
      onClose();
    } catch (err) {
      console.log('Valores HANDLESUBMIT%%%%%%%%%%%%%%', data); // Adicione este console.log

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      // Lógica para exibir mensagens de erro no toast caso necessário
    }
  }
  console.log('Valores em sectors:%%%%%%%%%%%%%%%%', sectors); // Adicione este console.log

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h1>Adicionar Assunto</h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  name="title"
                  icon={FiArchive}
                  placeholder="Nome do Assunto"
                />
                <Input
                  name="description"
                  icon={FiFileText}
                  placeholder="Descrição"
                />
                {/* Use o componente SectorInput para selecionar um setor */}
                {console.log('Valores em sectors$$$$$$:', sectors)}

                <SectorInput name="sector_id" sectors={sectors} />

                <div>
                  <label>Status:</label>
                  <select
                    name="isActive"
                    value={isActive === undefined ? '1' : isActive.toString()}
                    onChange={e => setIsActive(Number(e.target.value))}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>
                <Input name="isActive" type="hidden" value={isActive} />

                <Button type="submit">Adicionar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default AddSubjectModal;
