import React, { useEffect, useState } from 'react';
import { FiEdit, FiFrown, FiTrash2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import AddUserModal, { NewUserData } from './Modal';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import EditUserModal, { EditedUserData } from './Modal/EditUserModal';

import Header from '../../../components/Header';

import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';

interface UserData {
  isProvider: number;
  id: string;
  name: string;
  email: string;

  sector: {
    id: number;
    title: string;
  };
}

const UsersList: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [users, setUsers] = useState<UserData[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle

  const { addToast } = useToast();

  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<EditedUserData | null>(null);
  const [loading, setLoading] = useState(true);

  const [sectors, setSectors] = useState<any[]>([]); // Estado para armazenar os setores

  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors'); // Certifique-se de que esta rota está correta
        setSectors(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    // Verifica se o modal está aberto antes de carregar os setores
    if (showModal || selectedUser) {
      // Verifica se o modal está aberto antes de carregar os setores
      loadSectors();
    }
  }, [showModal, selectedUser]);
  // Função para abrir o modal de edição
  function handleOpenEditModal(user: UserData): void {
    const editedUser: EditedUserData = {
      id: user.id,
      name: user.name,
      email: user.email,
      sector_id: user.sector.id,
    };
    setSelectedUser(editedUser);
    setShowEditModal(true);
  }

  // Função para fechar o modal de edição
  function handleCloseEditModal(): void {
    setShowEditModal(false);
    setSelectedUser(null);
  }

  // Função para abrir o modal
  function handleOpenModal() {
    setShowModal(true);
  }

  // Função para fechar o modal
  async function handleCloseModal(): Promise<void> {
    setShowModal(false);
    try {
      const response = await api.get('/provider/provider_users');
      const providerUsers = response.data.filter(
        (userData: UserData) =>
          userData.isProvider === 1 || userData.isProvider === 2,
      );
      if (isMounted) {
        setUsers(providerUsers);
      }
    } catch (error) {
      //console.log('Erro ao buscar os usuários:', error);
      addToast({
        type: 'error',
        title: 'Erro ao buscar usuários',
        description:
          'Ocorreu um erro ao buscar os usuários. Por favor, tente novamente mais tarde.',
      });
    }
  }

  // Redirecionamento para outra página caso o usuário não seja um provedor
  useEffect(() => {
    if (user.isProvider === 0) {
      history.push('/user/dashboard');
    }
  }, [user.isProvider, history]);

  useEffect(() => {
    async function loadUsers(): Promise<void> {
      try {
        const response = await api.get('/provider/provider_users');
        const providerUsers = response.data.filter(
          (userData: UserData) =>
            userData.isProvider === 1 || userData.isProvider === 2,
        );
        if (isMounted) {
          setUsers(providerUsers);
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar usuários',
          description:
            'Ocorreu um erro ao buscar os usuários. Por favor, tente novamente mais tarde.',
        });
      } finally {
        setLoading(false); // Defina loading como false após a chamada da API
      }
    }

    loadUsers();
  }, [isMounted, addToast]);

  // Função para cadastrar um novo usuário
  async function handleAddUser(newUser: NewUserData): Promise<void> {
    try {
      await api.post('/provider/provider_users/newUser', newUser);
      addToast({
        type: 'success',
        title: 'Cadastro realizado!',
        description: 'Novo usuário cadastrado com sucesso!',
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          'Ocorreu um erro ao cadastrar o novo usuário. Por favor, tente novamente.',
      });
    }
  }

  // Função para editar um usuário
  async function handleEditUser(updatedUser: EditedUserData): Promise<void> {
    try {
      await api.put(`/provider/provider_users/${updatedUser.id}`, updatedUser);
      addToast({
        type: 'success',
        title: 'Usuário atualizado!',
        description: 'O usuário foi atualizado com sucesso.',
      });
      setShowEditModal(false);
      setSelectedUser(null);
      const response = await api.get('/provider/provider_users');
      const providerUsers = response.data.filter(
        (userData: UserData) =>
          userData.isProvider === 1 || userData.isProvider === 2,
      );
      if (isMounted) {
        setUsers(providerUsers);
      }
    } catch (error) {
      //console.log('Erro ao atualizar o usuário:', error);
      addToast({
        type: 'error',
        title: 'Erro ao atualizar usuário',
        description:
          'Ocorreu um erro ao atualizar o usuário. Por favor, tente novamente mais tarde.',
      });
    }
  }

  // Função para deletar um usuário
  async function handleDeleteUser(userId: string): Promise<void> {
    try {
      const confirmDelete = window.confirm(
        'Tem certeza de que deseja excluir o usuário?',
      );
      if (!confirmDelete) {
        return;
      }
      await api.delete(`/provider/provider_users/${userId}`);
      const response = await api.get('/provider/provider_users');
      const providerUsers = response.data.filter(
        (userData: UserData) =>
          userData.isProvider === 1 || userData.isProvider === 2,
      );
      if (isMounted) {
        setUsers(providerUsers);
      }
      addToast({
        type: 'success',
        title: 'Usuário deletado!',
        description: 'O usuário foi deletado com sucesso.',
      });
    } catch (error) {
      // console.log('Erro ao deletar o usuário:', error);
      addToast({
        type: 'error',
        title: 'Erro ao deletar usuário',
        description:
          'Ocorreu um erro ao deletar o usuário. Por favor, tente novamente mais tarde.',
      });
    }
  }
  return (
    <>
      <Header />

      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Content>
            <AddButton onClick={handleOpenModal}>
              <strong>Adicionar</strong>
            </AddButton>
            {users.length >= 1 ? (
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Setor</th>
                      <th>Editar</th>
                      <th>Deletar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.sector?.title || 'Geral'}</td>
                        <td>
                          <IconButton onClick={() => handleOpenEditModal(user)}>
                            <FiEdit size={20} />
                          </IconButton>
                        </td>
                        <td>
                          <IconButton onClick={() => handleDeleteUser(user.id)}>
                            <FiTrash2 size={20} />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            ) : (
              <EmptyList>
                <FiFrown size={50} color="#333" />
                <h2>Oooops... Não existem usuários cadastrados.</h2>
              </EmptyList>
            )}
          </Content>
        )}
      </Container>

      {showModal && (
        <AddUserModal
          isOpen={showModal}
          onClose={handleCloseModal}
          handleAddUser={handleAddUser}
          sectors={sectors}
        />
      )}
      {selectedUser && (
        <EditUserModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          user={selectedUser}
          handleEditUser={handleEditUser}
          sectors={sectors}
        />
      )}
    </>
  );
};

export default UsersList;
