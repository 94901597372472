import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/User/Dashboard';
import MyAppointments from '../pages/User/MyAppointments';

import Landing from '../pages/Landing';
import Appointments from '../pages/UserProvider/Appointments';
import Sectors from '../pages/UserProvider/Sectors';
import ServiceQueue from '../pages/UserProvider/ServiceQueue';
import Subjects from '../pages/UserProvider/Subjects';
import Users from '../pages/UserProvider/Users';
import Schedules from '../pages/UserProvider/Schedules';
import Faq from '../pages/UserProvider/Faq';
import DayOff from '../pages/UserProvider/DayOff';
import Config from '../pages/UserProvider/Settings';
import HourOff from '../pages/UserProvider/HourOff';
import DashboardProvider from '../pages/UserProvider/Dashboard';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route path="/signin" component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/user/dashboard" component={Dashboard} isPrivate />
    <Route path="/user/my_appointments" component={MyAppointments} isPrivate />
    
    <Route path="/provider/config" component={Config} isPrivate isProvider />

    <Route
      path="/provider/Appointments"
      component={Appointments}
      isPrivate
      isProvider
    />
    <Route
      path="/provider/Users"
      component={Users}
      isPrivate
      isProvider
    />
    <Route
      path="/provider/Sectors"
      component={Sectors}
      isPrivate
      isProvider
    />
    <Route
      path="/provider/Subjects"
      component={Subjects}
      isPrivate
      isProvider
    />
      <Route
      path="/provider/dashboard"
      component={DashboardProvider}
      isPrivate
      isProvider
    />
    <Route
      path="/provider/Schedules"
      component={Schedules}
      isPrivate
      isProvider
    />
        <Route path="/provider/Faq" component={Faq} isPrivate isProvider />
        <Route path="/provider/DayOff" component={DayOff} isPrivate isProvider />
        <Route path="/provider/HourOff" component={HourOff} isPrivate isProvider />
    <Route
      path="/provider/ServiceQueue"
      component={ServiceQueue}
      isPrivate
      isProvider
    />
  </Switch>
);

export default Routes;
