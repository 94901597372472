import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { FiMail, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
// import SectorInput from './SectorInput'; //
import SectorInput from '../../Schedules/Modal/SectorInput';

import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleEditUser: (editedUser: EditedUserData) => Promise<void>;
  sectors: any[];

  user: {
    id: string;
    name: string;
    email: string;
    sector_id: number;
  };
}

export interface EditedUserData {
  id: string;
  name: string;
  email: string;
  sector_id: number;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  handleEditUser,
  user,
  sectors,
}) => {
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(data: EditedUserData): Promise<void> {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        id: Yup.string().required('ID obrigatório'),
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        sector_id: Yup.string().required('Selecione um setor'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await handleEditUser(data);
      onClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      // Lógica para exibir mensagens de erro no toast caso necessário
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h2>Editar Usuário</h2>
              <Form ref={formRef} onSubmit={handleSubmit} initialData={user}>
                <Input name="id" type="hidden" defaultValue={user.id} />
                <Input name="name" icon={FiUser} placeholder="Nome" />
                <Input name="email" icon={FiMail} placeholder="E-mail" />
                <SectorInput name="sector_id" sectors={sectors} />

                <Button type="submit">Salvar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default EditUserModal;
