// criar pagina de sectors
import React, { useEffect, useState } from 'react';
import { FiEdit, FiFrown } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';

import Header from '../../../components/Header';
import AddSectorModal, { NewSectorData } from './Modal';
import EditSectorModal, { EditSectorData } from './Modal/EditSectorModal';
import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';
import { Spinner } from './styles';

interface SectorData {
  id: number;
  title: string;
  isActive: number;
}

const Sectors: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [sectors, setSectors] = useState<SectorData[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSector, setSelectedSector] = useState<EditSectorData | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  function handleOpenEditModal(sector: SectorData): void {
    const editedSector: EditSectorData = {
      id: sector.id,
      title: sector.title,
      isActive: sector.isActive,
    };
    setSelectedSector(editedSector);
    setShowEditModal(true);
  }

  // Função para fechar o modal de edição
  function handleCloseEditModal(): void {
    setShowEditModal(false);
    setSelectedSector(null);
  }

  // Função para abrir o modal
  function handleOpenModal() {
    setShowModal(true);
  }

  // função para fechar o modal

  async function handleCloseModal(): Promise<void> {
    setShowModal(false);
    try {
      const response = await api.get('/sectors');
      setSectors(response.data);
    } catch (error) {
      //console.log('Erro ao buscar os setores:', error);
      addToast({
        type: 'error',
        title: 'Erro ao buscar setores',
        description:
          'Ocorreu um erro ao buscar os setores. Por favor, tente novamente mais tarde.',
      });
    }
  }

  // Redirecionamento para outra página caso o usuário não seja um provedor

  // Redirecionamento para outra página caso o usuário não seja um provedor
  useEffect(() => {
    if (user.isProvider === 0) {
      history.push('/user/dashboard');
    }
  }, [user.isProvider, history]);

  // buscar setores
  useEffect(() => {
    async function loadSectors(): Promise<void> {
      try {
        const response = await api.get('/sectors');
        const providerSector = response.data.filter(
          (sector: SectorData) =>
            sector.isActive === 0 || sector.isActive === 1,
        );
        if (isMounted) {
          setSectors(providerSector);
        }
      } catch (error) {
        //console.log('Erro ao buscar os setores:', error);
        addToast({
          type: 'error',
          title: 'Erro ao buscar setores',
          description:
            'Ocorreu um erro ao buscar os setores. Por favor, tente novamente mais tarde.',
        });
      } finally {
        setLoading(false); // Defina loading como false após a chamada da API
      }
    }

    loadSectors();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  // Função para cadastrar um novo setor
  async function handleAddSector(newSector: NewSectorData): Promise<void> {
    try {
      await api.post('/sectors', newSector);
      addToast({
        type: 'success',
        title: 'Cadastro realizado!',
        description: 'Novo setor cadastrado com sucesso!',
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          'Ocorreu um erro ao cadastrar o novo setor. Por favor, tente novamente.',
      });
    }
  }

  // Função para editar um setor
  async function handleEditSector(updateSector: EditSectorData): Promise<void> {
    // console.log(updateSector.id);
    try {
      await api.put(`/sectors/${updateSector.id}`, updateSector);

      addToast({
        type: 'success',
        title: 'Atualização realizada!',
        description: 'Setor atualizado com sucesso!',
      });
      setShowEditModal(false);
      setSelectedSector(null);

      const response = await api.get('/sectors');
      const allSectors = response.data;
      setSectors(allSectors);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro ao atualizar o setor. Por favor, tente novamente.',
      });
    }
  }

  // função para deletar um

  async function handleDeleteSector(id: number): Promise<void> {
    try {
      const confirmDelete = window.confirm(
        'Tem certeza que deseja deletar este setor?',
      );
      if (!confirmDelete) {
        return;
      }
      await api.delete(`/sectors/${id}`);
      const response = await api.get('/sectors');
      const allSectors = response.data;
      if (isMounted) {
        setSectors(allSectors);
      }
      addToast({
        type: 'success',
        title: 'Setor deletado!',
        description: 'O setor foi deletado com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar setor',
      });
    }
  }

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Content>
            <AddButton onClick={handleOpenModal}>
              <strong>Adicionar</strong>
            </AddButton>

            {sectors.length >= 1 ? (
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      <th>Setor</th>
                      <th>Ativo</th>
                      <th>Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sectors.map(sector => (
                      <tr key={sector.id}>
                        <td>{sector.title}</td>
                        <td>{sector.isActive === 1 ? 'Sim' : 'Não'}</td>
                        <td>
                          <IconButton
                            onClick={() => handleOpenEditModal(sector)}
                          >
                            <FiEdit size={20} />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            ) : (
              <EmptyList>
                <FiFrown size={50} color="#333" />
                <h2>Oooops... Não existem Setores cadastrados.</h2>
              </EmptyList>
            )}
          </Content>
        )}
      </Container>
      {showModal && (
        <AddSectorModal
          isOpen={showModal}
          onClose={handleCloseModal}
          handleAddSector={handleAddSector}
        />
      )}

      {selectedSector && (
        <EditSectorModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          handleEditSector={handleEditSector}
          sector={selectedSector} // Adiciona a propriedade sector ao objeto
        />
      )}
    </>
  );
};

export default Sectors;
