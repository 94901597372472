import { darken, lighten } from 'polished';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

interface AvailableProps {
  enabled: boolean;
}

interface TimesProps {
  columns?: number;
}

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh; /* Define a altura mínima da página para preencher a tela inteira */

  @media (min-width: 650px) {
    overflow: auto;
    height: 100%;
  }
`;
/* */
export const Content = styled.div`
  height: auto;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 650px) {
    height: auto;
  }
`;

export const MobileNavigation = styled.div`
  @media (max-width: 650px) {
    margin-bottom: 40px;
    margin-top: 40px;

    h1 {
      color: #fff;
      font-size: 22px;
      font-weight: bold;

      a {
        text-decoration: none;
        color: #990000;
        transition: color 0.2s;

        &:hover {
          color: ${lighten(0.1, '#990000')};
        }
      }
    }
  }

  @media (min-width: 651px) {
    display: none;
  }
`;

export const AvailableTimesContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;

  padding: 5px;
  margin: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;
  height: 100%;

  h3 {
    margin-top: 20px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    width: 90%;

    margin-bottom: 40px;

    h3 {
      font-size: 14px;
    }
  }
`;
export const BoxH2 = styled.h2`
  background-color: #d3d3d3;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  color: #000000;
  font-size: 25px;
  font-weight: 0;
`;

export const BoxContainer = styled.div`
  background: #f8f8ff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  padding: 15px 10px 10px 15px;
  margin-top: 20px;
  width: 50%; /* Defina aqui a largura desejada em porcentagem */
  justify-content: center; /* Centralizar horizontalmente */
`;

export const PickerContainer = styled.div`
  display: flex;
  justify-content: center; /* Centralizar horizontalmente */
  width: 100%;
`;

export const SectorSelect = styled.select`
  font-size: 20px;
  width: 100%;
  border-radius: 4px;
  background: #f0f0f0;
  margin-bottom: 10px;
  border: none;
  padding: 5px 30px;
  align-self: center;
  text-align: center;

  cursor: pointer;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
`;

export const Picker = styled(DatePicker)`
  border: none;
  width: 100%;
  color: #000;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  text-align: center;
  padding: 5px 0;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSectorInput = styled.div`
  text-align: center;

  select {
    background-color: #ffffffce;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    label {
      font-size: 1.1rem;
      color: #ad3333;
    }

    input {
      background-color: #f9f9f8; /* Defina a mesma cor de fundo */
      border: none;
      text-align: center;
      color: black;
      &[type='date'] {
        padding-left: 3rem;
      }

      &::placeholder {
        color: black;
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const AvailableTimes = styled.div`
  background: #000;
  border-radius: 10px;
  text-align: center;
  padding: 10px 40px;
  border: 2px solid #990000;
  color: #fff;
  background-color: #990000;
  transition: opacity 0.3s;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  opacity: ${props => (props.enabled ? 1 : 0.5)};
  ${props => !props.enabled && 'pointer-events: none;'}


  opacity: ${(props: AvailableProps) => (props.enabled ? 1 : 0.5)};

  ${(props: AvailableProps) => !props.enabled && 'pointer-events: none;'}

  &:hover {
    cursor: ${(props: AvailableProps) =>
      props.enabled ? 'pointer' : 'not-allowed'};
    opacity: ${(props: AvailableProps) => props.enabled && 0.8};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const CountBadge = styled.span`
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 22px;
`;

export const Times = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: ${(props: TimesProps) =>
    props.columns === 2 && 'repeat(2, 1fr)'};
  row-gap: 20px;
  column-gap: 20px;

  margin-top: 40px;

  .selected {
    border: 2px solid #fff;
    background-color: ${darken(0.2, '#990000')};
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 17px;

  > div {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;

      @media (max-width: 650px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }

    > div {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 2px solid #990000;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div.first-square {
      background-color: #990000;
    }

    > div.second-square {
      background-color: #990000;
      opacity: 0.5;
    }

    > div.third-circle {
      background-color: #fff;
      border: 2px solid #990000;
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }

    @media (max-width: 650px) {
      font-size: 15px;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ConfirmButton = styled.button`
  border: 0;
  background: none;
  padding: 10px 40px;
  background-color: #990000;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 40px;
  transition: opacity 0.3s;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const ConfirmInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  > strong {
    font-size: 14px;
    color: #444444;
    margin-bottom: 7px;
    border-bottom: 1px solid #999;
    padding-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #666666;
    padding: 20px 0;
    text-align: center;
  }

  input {
    border-radius: 4px;
    padding: 15px 10px;
    border: 1px solid #999;
    margin-bottom: 20px;
    font-size: 18px;
    color: #444444;
  }

  button {
    border: 0;
    background: none;
    color: #fff;
    font-size: 20px;
    background-color: #990000;
    border-radius: 10px;
    padding: 15px 0;
  }
`;

export const AgendamentoSelect = styled.select`
  font-size: 20px;
  text-align:center;
  border-radius: 4px;
  background: #f0f0f0;
  margin-bottom: 16px;
  border: none;
  padding: 5px 30px;
  align-self: center;

  cursor: pointer;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);


  option[title]:hover::before {
    content: attr(title);
    position: absolute;
    z-index: 1;
    top: -25px;
    left: 0;
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
`;

export const Section = styled.section`
  position: relative;brightness
  margin-top: 0rem;
  width: 100%;
  height: 100%;

  .background {
    position: relative;
    height: 100%;
    overflow: hidden; /* Para esconder o conteúdo fora do contêiner */
    
    img {
      width: 100%;
      filter: brightness(35%); /* Reduz o brilho da imagem */
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(153, 0, 0, 0.2); /* Vermelho com 50% de transparência */
      z-index: 1; /* Coloque a camada vermelha sobre a imagem */
    }}
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #990000;
          
        }
        input {
          background-color: #F9F9F8;
          border: none;
          text-align: center;
          color: black;
          &[type='date'] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
    
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type='date'] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
export const FooterFixed = styled.div`
  position: sticky;

  bottom: 0;
  width: 100%;
  flex-shrink: 0; /* Impede que o footer encolha quando há pouco conteúdo */
`;
export const Section2 = styled.section`
  position: relative;
  margin-top: 2rem; /* Espaçamento superior de 2rem (ajuste conforme necessário) */
  width: 100%;
  padding: 2rem 0rem; /* Adicione espaço interno de 2rem em todas as direções (ajuste conforme necessário) */

  .background {
    position: relative;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      filter: brightness(50%);
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .title {
      color: white;
      h1 {
        font-size: 2rem;
        letter-spacing: 0.1rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }

    .search {
      /* Estilos para a seção de pesquisa, se necessário */
    }
  }

  @media screen and (min-width: 280px) and (max-width: 980px) {
    .content {
      
    
    .title {
      color: white;
      font-size: 5px;
      h1 {
        font-size: 20px;
        letter-spacing: 0.1rem;
      }
  }
}
`;

export const ContactButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
`;

export const WhatsAppButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #990000; /* Cor padrão do WhatsApp */
  padding: 5px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.1, 'red')};
  }

  svg {
    margin-right: 8px; /* Espaço entre o ícone e o texto. Se for usar ícone. */
  }
`;

export const PhoneButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #990000; /* Cor padrão do WhatsApp */
  padding: 5px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.1, 'red')};
  }

  svg {
    margin-right: 8px; /* Espaço entre o ícone e o texto. Se for usar ícone. */
  }
`;

