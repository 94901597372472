import React from 'react';
import logo from '../../assets/logo.svg';
import { useAuth } from '../../hooks/auth';

import { Container, HeaderContent, Nav, Navigation, UserInfo } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <img src={logo} alt="CFO" />

      <HeaderContent>
        {/* <UserInfo> </UserInfo> */}
        {/* <h2>Bem-vindo, {user.name}</h2> */}
        <Navigation>
          <Nav to="/signin">Login</Nav>
          <Nav to="/signup">Cadastre-se</Nav>
        </Navigation>
      </HeaderContent>
    </Container>
  );
};

export default Header;
