// criar pagina de schedules
import React, { useEffect, useState } from 'react';
import { FiFrown, FiTrash2, FiEdit } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import Header from '../../../components/Header';
import AddDayOffModal from './Modal';
import Appointments from '../Appointments';

import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';
import { ca, tr } from 'date-fns/locale';
import { add, set } from 'date-fns';

interface HourOff {
  id: number;
  date: Date;
  description: string;
  sector_id: string;
  hourInitial: string;
  hourFinal: string;
  sector: {
    id: number;
    title: string;
  };
}

const HourOff: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [hourOffs, setHourOffs] = useState<HourOff[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [sectors, setSectors] = useState<any[]>([]); // Estado para armazenar os setores

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors'); // Certifique-se de que esta rota está correta
        setSectors(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    if (showModal) {
      // Verifica se o modal está aberto antes de carregar os setores
      loadSectors();
    }
  }, [showModal]);

  useEffect(() => {
    async function loadHourOff(): Promise<void> {
      try {
        const response = await api.get('/provider/houroff');
        setHourOffs(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os horario off',
          description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    }

    loadHourOff();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  // quero mostrar os dados do dayoffs  no console

  function handleOpenModal(): void {
    setShowModal(true);
  }

  async function handleCloseModal(): Promise<void> {
    setShowModal(false);

    try {
      const response = await api.get('/provider/houroff');
      setHourOffs(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os dados ',
        description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
      });
    }
  }

  async function handleAddHourOff(newHourOff: HourOff): Promise<void> {
    console.log(
      newHourOff.date +
        '  +++++  ' +
        newHourOff.description +
        '  +++++  ' +
        newHourOff.id,
    );
    try {
      console.log('PASSOU PELO TRY');
      await api.post('/provider/houroff', newHourOff);
      console.log('  ++++' + newHourOff);
      addToast({
        type: 'success',
        title: 'Dia de folga adicionado!',
        description: 'O horario off foi adicionado com sucesso.',
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar horario off',
        description:
          'Ocorreu um erro ao adicionar o horario off, tente novamente.',
      });
    }
  }

  async function handleDeleteHourOff(id: number): Promise<void> {
    try {
      const confirmDelet = window.confirm(
        'Tem certeza que deseja deletar este horario off?',
      );

      if (!confirmDelet) {
        return;
      }
      await api.delete(`/provider/houroff/${id}`);
      const response = await api.get('/provider/houroff');
      const allHourOffs = response.data;
      if (isMounted) {
        setHourOffs(allHourOffs);
      }
      addToast({
        type: 'success',
        title: 'Dia de folga deletado!',
        description: 'O horario off foi deletado com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar Horario',
        description:
          'Ocorreu um erro ao deletar o horario off, tente novamente.',
      });
    }
  }
  console.table(hourOffs);

  return (
    <>
      <Header />
      <Container>
        <Content>
       

          {loading ? (
            <Spinner />
          ) : (
            <>
              <AddButton onClick={handleOpenModal}>
                <strong>Adicionar</strong>
              </AddButton>
              {hourOffs.length === 0 ? (
                <EmptyList>
                  <FiFrown />
                  <span>Nenhum horario off cadastrado</span>
                </EmptyList>
              ) : (
                <Scroll>
                  <table>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th> Setor</th>
                        <th>Horários</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hourOffs.map(hourOff => (
                        <tr key={hourOff.id}>
                          <td>{new Date(hourOff.date).toLocaleDateString()}</td>{' '}
                          <td>{hourOff.description}</td>
                          <td>{hourOff.sector.title}</td>
                          <td>
                            {hourOff.hourInitial + '     ' + hourOff.hourFinal}
                          </td>
                          <td>
                            <IconButton
                              onClick={() => handleDeleteHourOff(hourOff.id)}
                            >
                              <FiTrash2 />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Scroll>
              )}
            </>
          )}
        </Content>
      </Container>
      <AddDayOffModal
        isOpen={showModal}
        onClose={handleCloseModal}
        handleAddHourOff={handleAddHourOff}
        sector_id={sectors}
      />
    </>
  );
};

export default HourOff;
