import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiMapPin, FiSliders } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SectorInput from './SectorInput';
import moment from 'moment';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FormControl, FormGroup, FormLabel, Box } from '@material-ui/core';

import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

export interface NewSchedulesData {
  id: number;
  hour: string;
  appointmentQuantity: number;
  sector_id: number;
  exceptionDays: number[]; // Change the type to allow an array of numbers
}

interface AddSchedulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddSchedules: (newSchedules: NewSchedulesData) => Promise<void>;
  sectors: any[];
  setFormattedData: React.Dispatch<React.SetStateAction<NewSchedulesData>>; // Define the "setFormattedData" function
}

const AddSchedulesModal: React.FC<AddSchedulesModalProps> = ({
  isOpen,
  onClose,
  handleAddSchedules,
  sectors,
  setFormattedData, // Pass the "setFormattedData" function as a prop
}) => {
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const handleCheckboxChange = (day: number) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter(d => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
    setFormattedData({ ...formattedData, exceptionDays: selectedDays }); // Atualiza o estado "formattedData" com os dias de exceção selecionados
  };
  const formRef = useRef<FormHandles>(null);

  const [data, setData] = useState<NewSchedulesData>({
    id: 0,
    hour: '', // O campo "hour" será preenchido com a hora selecionada pelo usuário
    appointmentQuantity: 0,
    sector_id: 0,
    exceptionDays: [], // Adicionando o campo "exceptionDays" ao estado
  });

  const [showMessage, setShowMessage] = useState<boolean>(false); // Variável de estado para controlar a exibição da mensagem

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, hour: e.target.value });
  };

  async function handleSubmit(data: NewSchedulesData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        hour: Yup.string().required('Hora obrigatória'),
        appointmentQuantity: Yup.number().required('Quantidade obrigatória'),
        sector_id: Yup.number().required('Setor obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      console.log('Dados a serem enviados:', data); // Adicionando um console.log para exibir os dados que serão enviados
      const exceptionDays = selectedDays;
      console.log('DIAS DE EXCEÇÃO   %%%%%%%%%%%%%%%%%%    ' + exceptionDays);
      // Formata a data no formato desejado
      const formattedData = {
        ...data,
        hour: moment(data.hour, 'HH:mm').format('HH:mm'),
        exceptionDays: selectedDays, // Adicionando os dias selecionados ao objeto de dados
      };
      const newData: NewSchedulesData = {
        ...formattedData,
        exceptionDays: exceptionDays,
      };
      console.log('DIAS DE EXCEÇÃO   ______    ' + formattedData.exceptionDays);
      console.log(
        'New data  - DIAS DE EXCEÇAO #######' + newData.exceptionDays,
      );
      console.log('Dados completos a serem enviados:', newData); // Adicionando um console.log para exibir todos os dados que serão enviados

      await handleAddSchedules(newData);
      setShowMessage(true); // Ativa a exibição da mensagem
      setTimeout(() => {
        setShowMessage(false); // Desativa a exibição da mensagem após alguns segundos
        onClose();
      }, 3000); // A mensagem será ocultada após 3 segundos
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }

    const handleAddSchedules1 = async (newSchedules: NewSchedulesData) => {
      console.log('Dados a serem adicionados:', newSchedules);
      // Restante do código para adicionar os dados...
    };
  }

  const formattedData: NewSchedulesData = {
    id: 0,
    hour: '',
    appointmentQuantity: 0,
    sector_id: 0,
    exceptionDays: [],
  }; // Define a "formattedData" variable and initialize it with the default values for the "NewSchedulesData" interface

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h1>Adicionar Horário</h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="time-picker-container">
                  <Input name="hour" type="time" onChange={handleChange} />
                </div>

                <Input
                  name="appointmentQuantity"
                  icon={FiSliders}
                  placeholder="Quantidade"
                />
                <SectorInput name="sector_id" sectors={sectors} />

                <FormControl>
                  <FormLabel component="legend">Dias de exceção</FormLabel>
                  <FormGroup>
                    <Box display="flex" flexDirection="row">
                      {' '}
                      {/* Use o componente Box para agrupar os checkboxes em uma única linha */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(1)}
                            onChange={() => handleCheckboxChange(1)}
                          />
                        }
                        label="Segunda"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(2)}
                            onChange={() => handleCheckboxChange(2)}
                          />
                        }
                        label="Terça"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(3)}
                            onChange={() => handleCheckboxChange(3)}
                          />
                        }
                        label="Quarta"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(4)}
                            onChange={() => handleCheckboxChange(4)}
                          />
                        }
                        label="Quinta"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(5)}
                            onChange={() => handleCheckboxChange(5)}
                          />
                        }
                        label="Sexta"
                      />
                    </Box>
                  </FormGroup>
                </FormControl>
                {console.log('' + data)}
                <Button type="submit">Adicionar</Button>
                <Button type="button" onClick={onClose}>
                  Cancelar
                </Button>
              </Form>
              {showMessage && <div className="success-message">Sucesso</div>}
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default AddSchedulesModal;
