import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiMapPin, FiSliders } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

interface EditSectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleEditSector: (editedSector: EditSectorData) => Promise<void>;
  sector: {
    id: number;
    title: string;
    isActive: number;
  };
}

export interface EditSectorData {
  id: number;
  title: string;
  isActive: number;
}

const EditSectorModal: React.FC<EditSectorModalProps> = ({
  isOpen,
  onClose,
  handleEditSector,
  sector,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState<number>(sector.isActive);

  async function handleSubmit(data: EditSectorData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        id: Yup.string().required('ID obrigatorio'),
        title: Yup.string().required('Titulo obrigatorio'),
        isActive: Yup.string().required('Ativo obrigatorio'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await handleEditSector(data);
      onClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <Form ref={formRef} onSubmit={handleSubmit} initialData={sector}>
                <h1>Editar Setor</h1>
                <Input name="id" defaultValue={sector.id} type="hidden" />
                {/* <Input name="id" icon={FiUser} placeholder="ID" /> */}
                <Input
                  name="title"
                  icon={FiMapPin}
                  placeholder="Nome do setor"
                />
               <select
                  name="isActive"
                  value={isActive !== undefined ? isActive.toString() : ''}
                  onChange={e => setIsActive(Number(e.target.value))}
                >
                  {isActive === 0 ? (
                    <>
                      <option value="0">Inativo</option>
                      <option value="1">Ativo</option>
                    </>
                  ) : (
                    <>
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </>
                  )}
                </select>
                <Input name="isActive" type="hidden" value={isActive} />
                <Button type="submit">Salvar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};
export default EditSectorModal;
