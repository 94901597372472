import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiArchive, FiFileText, FiMapPin } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';
import Faq from '../../Faq';

export interface NewFaqData {
  id: number;
  question: string;
  answer: string;
}

interface AddFaqModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddFaq: (newFaq: NewFaqData) => Promise<void>;
}
const AddFaqModal: React.FC<AddFaqModalProps> = ({
  isOpen,
  onClose,
  handleAddFaq,
}) => {
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(data: NewFaqData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        question: Yup.string().required('Questão obrigatória'),
        answer: Yup.string().required('Resposta obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // console.log('Valores HANDLESUBMIT       &&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log

      await handleAddFaq(data);
      onClose();
    } catch (err) {
      // console.log('Valores HANDLESUBMIT&&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      // Lógica para exibir mensagens de erro no toast caso necessário
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h1>Adicionar Faqs</h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="question" icon={FiArchive} placeholder="Questão" />
                <Input name="answer" icon={FiFileText} placeholder="Resposta" />

                <Button type="submit">Adicionar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default AddFaqModal;
