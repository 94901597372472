import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2
em;
  height: 60px;
  background: #990000;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterText = styled.p`
  font-size: 14px;
  color: #fff;
  margin-right: 10px;

`;

export const Versao = styled.p`
  font-size: 14px;
  color: #fff;
  margin-right: 10px;

`;
export const FooterLink = styled.a`
  font-size: 14px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
export const FooterFixed = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;
