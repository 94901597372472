export const holidays = [
  new Date(2023, 9, 28),
  new Date(2023, 9, 29),
  new Date(2023, 10, 28),
  new Date(2023, 10, 29),
  new Date(2023, 10, 30),
  new Date(2023, 10, 31),
  new Date(2023, 1, 1),

];

export const libraryVacation = [
  new Date(2023, 9, 28),
  new Date(2023, 9, 29),
  new Date(2023, 10, 28),
  new Date(2023, 10, 29),
];

export const mumpsMigration = [

  new Date(2023, 9, 28),
  new Date(2023, 9, 29),
  new Date(2023, 10, 28),
  new Date(2023, 10, 29),
];
