import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../services/api';

import { AgendamentoSelect, ConfirmInformation } from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    padding: '25px',
    overflow: 'hidden',
    zIndex: 3,
  },
  overlay: {
    zIndex: 3,
    backgroundColor: '#44444480',
  },
};
interface Subject {
  id: number;
  title: string;
}
interface Props {
  time: {
    time: Date;
    parsedTime: string;
  };

  sector: number;
  isOpen: boolean;
  toggleModalVisible: () => void;
  handleAddAppointment(
    sector: number,
    time: Date,
    subject: string,
  ): Promise<void>;
}

Modal.setAppElement('#root');

const ConfirmModal: React.FC<Props> = ({
  time,
  sector,
  isOpen,
  toggleModalVisible,
  handleAddAppointment,
}: Props) => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const subjectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function loadSubjects() {
      if (sector !== 0) {
        try {
          const response = await api.get<Subject[]>(`/subjects/${sector}`);
          setSubjects(response.data);
        } catch (error) {
          console.error('Erro ao carregar assuntos:', error);
          setSubjects([]);
        }
      } else {
        setSubjects([]);
      }
    }

    loadSubjects();
  }, [sector]);

  function handleSelectSubject(e: React.ChangeEvent<HTMLSelectElement>): void {
    setSelectedSubject(e.target.value);
  }
  console.log(subjects);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalVisible}
        style={customStyles}
        // ... outro código
      >
        <ConfirmInformation>
          <strong>CONFIRMAR AGENDAMENTO</strong>

          <p>
            Confirmar atendimento para
            <strong> {time.parsedTime}</strong>?
          </p>

          <AgendamentoSelect
            name="AgendamentoSelect"
            onChange={handleSelectSubject}
            value={selectedSubject}
            required
          >
            <option hidden disabled value="">
              SELECIONE UMA OPÇÃO
            </option>
            {subjects.map((subject, index) => (
              <option key={index} value={subject.title}>
                {subject.title}
              </option>
            ))}
          </AgendamentoSelect>

          <input
            ref={subjectRef}
            type="hidden"
            value={selectedSubject}
            placeholder="Assunto"
          />

          <button
            type="button"
            onClick={() =>
              handleAddAppointment(sector, time.time, selectedSubject)
            }
          >
            Confirmar
          </button>
        </ConfirmInformation>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
