import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiMapPin, FiSliders } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

interface EditFaqsDataProps {
  isOpen: boolean;
  onClose: () => void;
  handleEditFaqs: (editedFaq: EditFaqsData) => Promise<void>;
  faqs: {
    id: number;
    question: string;
    answer: string;
  };
}

export interface EditFaqsData {
  id: number;
  question: string;
  answer: string;
}

const EditFaqModal: React.FC<EditFaqsDataProps> = ({
  isOpen,
  onClose,
  handleEditFaqs,
  faqs,
}) => {
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(data: EditFaqsData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        id: Yup.string().required('ID obrigatorio'),
        question: Yup.string().required('Questão obrigatória'),
        answer: Yup.string().required('Resposta obrigatória'),
      });
      // console.log('Valores HANDLESUBMIT       &&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log
      await schema.validate(data, {
        abortEarly: false,
      });

      await handleEditFaqs(data);
      onClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }
  const initialData = faqs || { id: 0, question: '', answer: '' };

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={initialData}
              >
                <h1>Editar Faqs</h1>
                <Input name="id" defaultValue={faqs.id} type="hidden" />
                <Input
                  name="question"
                  icon={FiSliders}
                  placeholder="Questões"
                />
                <Input name="answer" icon={FiMapPin} placeholder="Resposta" />
                <Button type="submit">Salvar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};
export default EditFaqModal;
