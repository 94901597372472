import DatePicker from 'react-datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 80%;
  height: 70%;
  margin: auto;
  background-color: #f0f0f0;
  padding: 20px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
      color: #333;
    }

    tr {
      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #e2e2e2;
      }
    }
  }

  h2 {
    text-align: center;
    margin-top: 30px;
    color: #333;
  }

  svg {
    margin-bottom: 10px;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  height: 100%;
`;

export const EmptyList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 20px;
    text-align: center;
  }
`;

export const FilterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > input {
      margin-right: 10px;
    }
  }
`;

export const Picker = styled(DatePicker)`
  border: none;
  font-size: 25px;
  color: #000;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  padding: 5px 0;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  cursor: pointer;
`;

export const AppointmentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppointmentData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;

  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const DateInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      letter-spacing: 1px;
    }
  }

  > p:last-child {
    border-right: 1px solid #333;
    padding-right: 10px;
    font-weight: bold;

    @media (max-width: 800px) {
      border: none;
      padding: 0;
      margin: 10px 0;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const AppointmentStatus = styled.div`
  display: flex;
  margin-left: 10px;

  svg {
    cursor: pointer;

    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AddButton = styled.div`
  display: inline-flex;
  justify-content: center; /* Centralizar horizontalmente o conteúdo */
  align-items: center; /* Centralizar verticalmente o conteúdo */
  width: 120px; /* Largura fixa de 120px */
  padding: 10px 20px;
  border: 2px solid #e53935;
  border-radius: 5px;
  background-color: #e53935;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #c62828;
    border-color: #c62828;
  }

  &:active {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }

  &:disabled {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    color: #666666;
    cursor: not-allowed;
  }
`;

export const IconButton = styled.button`
  /* Reset margin and padding for the button */
  margin: 0;
  padding: 0;
  /* Remove default button styling */
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Spinner = styled.div`
  border: 8px solid #f3f3f3; /* Cor de fundo do spinner */
  border-top: 8px solid #ff0000; /* Cor vermelha para a parte superior do spinner */

  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
