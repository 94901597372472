import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 60px;
  // padding: 10px 30px;

  padding: 10px 15%;
  background: #990000;
  img {
    max-height: 40px;
  }

  @media (max-width: 650px) {
    padding: 10px 15px;
    justify-content: space-between;
  }

  @media (min-width: 651px) {
    > svg {
      display: none;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinhar à direita */

  width: 100%;
  margin: 0 5%; /* Ajustado para dar um pouco mais de espaço nas laterais */

  @media (max-width: 650px) {
    justify-content: flex-end;
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;

  margin-left: 10px;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const Nav = styled(NavLink)`
  display: inline-block;
  padding: 8px 16px;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  font-family: 'Your Dashboard Font', sans-serif;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #800000;
  }

  &.active {
    background-color: #800000;
    border-color: #fff;
  }
`;

export const UserInfo = styled.div`
  font-family: 'Your Dashboard Font', sans-serif;

  h2 {
    font-size: 20px;
    margin-right: 50px;
    color: #fff;

    @media (max-width: 650px) {
      display: none;
    }
  }

  button {
    font-size: 15px;
    background: 0;
    border: none;
    color: #fff;
    transition: color 0.2s;
    font-family: 'Your Dashboard Font', sans-serif;

    &:hover {
      color: #999;
    }

    @media (max-width: 650px) {
      align-self: flex-end;
    }
  }
`;
