import React, { useEffect, useState } from 'react';
import { Container, FooterContent, FooterText, Versao } from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface Config {
  id: number;
  image: string;
  image_red: string;
  local: string;
  abreviation: string;
  phone: string;
  whatsapp: string;
  email: string;
  andress: string;
  imageFile?: File; // Campo opcional para o arquivo da imagem
  imageRedFile?: File;
}

const Footer: React.FC = () => {
  const [configs, setConfigs] = useState<Config[]>([]);
  const [formData, setFormData] = useState<Config | null>(null);
  const { addToast } = useToast();

  useEffect(() => {
    async function loadConfigs() {
      try {
        const response = await api.get('/config');
        setConfigs(response.data);

        const configToEdit = response.data.find(
          (config: Config) => config.id === 1,
        );
        if (configToEdit) {
          setFormData(configToEdit);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Dados de personalização nao carregados',
          description: 'Dados de personalização nao carregados',
        });
      }
    }
    loadConfigs();
  }, [addToast]);

  return (
    <Container>
      <FooterContent>
        <FooterText>
          © 2023 CFO | {formData?.abreviation}. Todos os direitos reservados.
        </FooterText>
        <br />
        <Versao> Versão 1.0.0</Versao>
      </FooterContent>
    </Container>
  );
};

export default Footer;
