import React, { ChangeEvent } from 'react';
import { useField } from '@unform/core';

interface SectorInputProps {
    name: string;
    sectors: any[]; // Adicione a propriedad2e sectors
}

const SectorInput: React.FC<SectorInputProps> = ({ name, sectors }) => {
    // console.log("NOME   " + name);
    // console.log("SETORES   " + sectors.map((sector) => (sector.name)));
    // console.log("SETORES  strinfy  ----------------\n", JSON.stringify(sectors, null, 2));

    const { fieldName, defaultValue, registerField, error } = useField(name);

    const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (registerField) {
            registerField({
                name: fieldName,
                ref: e.target,
                getValue: (selectRef: HTMLSelectElement) => {
                    return selectRef.value;
                },
            });
        }
    };

    return (
        <>
            <style>
                {`
          /* Estilo para desabilitar a primeira opção */
          select[name="${fieldName}"] option:first-child {
            pointer-events: none;
            user-select: none;
          }
        `}
            </style>
            <div style={{ textAlign: 'center' }}>
                <select
                    name={fieldName}
                    defaultValue={defaultValue}
                    onChange={handleOnChange}
                >
                    {/* {console.log("SETORES  DENTRO  ----------------\n", JSON.stringify(sectors, null, 2))} */}

                    <option value="">SELECIONE UM SETOR</option>
                    {sectors.map((sector) => (

                        <option value={sector.id}>
                            {sector.id} {sector.title}
                        </option>
                    ))}
                </select>
            </div>
            {error && <span>{error}</span>}
        </>
    );
};

export default SectorInput;
