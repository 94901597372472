import React, { useState } from 'react';
import './Accordion.css';
import { FiChevronDown } from 'react-icons/fi'; // Importe o ícone FiChevronDown

interface FAQItem {
  question: string;
  answer: string;
}

interface AccordionProps {
  faqs: FAQItem[];
}

const Accordion: React.FC<AccordionProps> = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="accordion">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => toggleAccordion(index)}
        >
          <div className="accordion-question">
            <span>{faq.question}</span>
            <span className="chevron-icon">
              <FiChevronDown size={16} />{' '}
              {/* Adicione o ícone FiChevronDown aqui */}
            </span>
          </div>
          <div className="accordion-answer">{faq.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
