import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
import { FiMenu, FiLogOut } from 'react-icons/fi'; // Importe um ícone de menu hambúrguer
import { useAuth } from '../../hooks/auth';

import {
  Container,
  HeaderContent,
  Nav,
  Navigation,
  UserInfo,
  MenuIcon,
} from './styles';

const Header: React.FC = () => {
  const { signOut, user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleLogout(): void {
    signOut();
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Container>
      <img src={logo} alt="CFO" />
      <MenuIcon onClick={toggleMenu}>
        <FiMenu />
      </MenuIcon>

      <HeaderContent>
        <Navigation isMenuOpen={isMenuOpen}>
          {user.isProvider === 0 ? (
            <>
              <br />
              <br />
              <br />
              <Nav to="/user/dashboard">Agendar</Nav>
              <Nav to="/user/my_appointments">Meus agendamentos</Nav>
            </>
          ) : user.isProvider === 1 ? (
            <>
              <br />
              <br />
              <br />
              <Nav to="/provider/appointments">Agendamentos</Nav>
            </>
          ) : (
            <>
              <br />
              <br />
              <br />
              <Nav to="/provider/dashboard">Dashboard</Nav>
              <Nav to="/provider/appointments">Agendamentos</Nav>
              <Nav to="/provider/users">Usuário</Nav>
              <Nav to="/provider/sectors">Setor</Nav>
              <Nav to="/provider/subjects">Assunto</Nav>
              <Nav to="/provider/schedules">Horário</Nav>
              <Nav to="/provider/dayOff">Dia Off</Nav>
              <Nav to="/provider/hourOff">Horas Off</Nav>
              <Nav to="/provider/faq">Faq</Nav>
              <Nav to="/provider/config">Configurações</Nav>
            </>
          )}
        </Navigation>

        <UserInfo>
          <h2>Bem-vindo, {user.name}</h2>

          <button type="button" onClick={handleLogout}>
            <FiLogOut />
            <span>Sair do sistema</span>
          </button>
        </UserInfo>
      </HeaderContent>
    </Container>
  );
};

export default Header;
