import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 600px) {
    border-radius: 0;
    max-width: 100%;
    height: 100%;
    padding: 10px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 16px;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    @media screen and (max-width: 600px) {
      padding: 8px;
      font-size: 14px;
    }
  }

  button {
    margin-top: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #c62828;
    color: #fff;
    font-size: 16px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

export const SelectInput = styled.select`
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background: #f0f0f0;
  margin-bottom: 16px;
  border: none;
  padding: 10px 20px;
  align-self: center;
  cursor: pointer;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  option[title]:hover::before {
    content: attr(title);
    position: absolute;
    z-index: 1;
    top: -25px;
    left: 0;
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;
