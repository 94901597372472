import DatePicker from 'react-datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 80%;
  height: 70%;
  margin: auto;
  background-color: #f0f0f0;
  padding: 20px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
      color: #333;
    }

    tr {
      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #e2e2e2;
      }
    }
  }

  h2 {
    text-align: center;
    margin-top: 30px;
    color: #333;
  }

  svg {
    margin-bottom: 10px;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  height: 100%;
`;

export const EmptyList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 20px;
    text-align: center;
  }
`;

export const FilterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > input {
      margin-right: 10px;
    }
  }
`;

export const Picker = styled(DatePicker)`
  border: none;
  font-size: 25px;
  color: #000;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  padding: 5px 0;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  cursor: pointer;
  // Novo: alterando a aparência do contorno/foco
  &:focus {
    outline-color: #4d90fe; // ou qualquer outra cor desejada
    outline-style: auto; // ou 'none' se você não quer um contorno
  }
  // Novo: alterando a aparência do contorno/foco
  &:focus {
    outline-color: #4d90fe; // ou qualquer outra cor desejada
    outline-style: auto; // ou 'none' se você não quer um contorno
  }
`;

export const AppointmentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppointmentData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;

  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const DateInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      letter-spacing: 1px;
    }
  }

  > p:last-child {
    border-right: 1px solid #333;
    padding-right: 10px;
    font-weight: bold;

    @media (max-width: 800px) {
      border: none;
      padding: 0;
      margin: 10px 0;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const AppointmentStatus = styled.div`
  display: flex;
  margin-left: 10px;

  svg {
    cursor: pointer;

    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AddButton = styled.div`
  display: inline-flex;
  justify-content: center; /* Centralizar horizontalmente o conteúdo */
  align-items: center; /* Centralizar verticalmente o conteúdo */
  width: 120px; /* Largura fixa de 120px */
  padding: 10px 20px;
  border: 2px solid #e53935;
  border-radius: 5px;
  background-color: #e53935;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #c62828;
    border-color: #c62828;
  }

  &:active {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }

  &:disabled {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    color: #666666;
    cursor: not-allowed;
  }
`;

export const IconButton = styled.button`
  /* Reset margin and padding for the button */
  margin: 0;
  padding: 0;
  /* Remove default button styling */
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Spinner = styled.div`
  border: 8px solid #f3f3f3; /* Cor de fundo do spinner */
  border-top: 8px solid #ff0000; /* Cor vermelha para a parte superior do spinner */

  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-between; // Espaço igual entre os gráficos
  align-items: flex-start; // Para evitar que os itens se estiquem
  flex-wrap: wrap; // Permite que os itens passem para a linha seguinte se não houver espaço suficiente
  gap: 20px; // Espaço entre os gráficos, pode ajustar conforme necessário

  // Certifique-se de que os gráficos não ocupem toda a largura se não houver espaço suficiente
  & > div {
    flex: 1 1 45%; // Isso significa que cada gráfico pode encolher e crescer, mas tenta manter pelo menos 45% da largura do container.
    min-width: 300px; // Evita que os gráficos fiquem muito pequenos
  }
`;
export const ChartBox = styled.div`
  background-color: #ffffff; // ou qualquer outra cor que você preferir
  border-radius: 10px; // para ter bordas arredondadas
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); // uma leve sombra para destacar o box
  padding: 20px; // espaço interno ao redor do gráfico
  margin: 10px; // espaço externo ao redor do box, caso você coloque vários deles perto um do outro
  height: auto; // ajusta a altura automaticamente baseado no conteúdo interno
  min-height: 400px; // altura mínima de 400px

  width: 100%; // ocupa 100% do espaço do container pai (ajustável conforme necessário)
  display: flex; // faz com que o conteúdo interno use flexbox
  justify-content: center; // centraliza o gráfico horizontalmente
  align-items: center; // centraliza o gráfico verticalmente
`;
export const Container1 = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  justifyContent: 'center',
  gap: 20px; // Espaço entre os DateInputWrappers
`;

export const Container2 = styled.div`
  display: flex;
  width: 100%; // Tornar o container de largura total

  align-items: center;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  justifyContent: 'center',
  gap: 20px; // Espaço entre os DateInputWrappers
`;

export const SelectWrapper = styled.div`
  width: 100%; // Ocupar toda a largura do container
  padding: 10px; // Adicionar algum espaçamento interno
  display: flex; // Usar flexbox para centralizar o select
  justify-content: center; // Centralizar o select horizontalmente
`;

export const DateInput = styled(DatePicker)`
  border: none;
  background-color: transparent;
  font-size: 16px;
  margin: 10px 10px 10px 0;
  outline: none;
  width: 100%;
`;

export const SectorInput = styled.input`
  border: none;
  background-color: transparent;
  font-size: 16px;
  outline: none;
`;
export const DateInputWrapper = styled.div`
  flex: 1; // Faz cada DateInputWrapper ocupar uma quantidade igual de espaço
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f9f9f9; // Cor de fundo para cada wrapper
  border-radius: 10px; // Borda arredondada
  padding: 5px 15px; // Padding dentro de cada DateInputWrapper
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); // Sombra interna para dar profundidade

  .calendar-icon {
    position: absolute;
    left: 10px;
    color: #333;
  }

  // Este estilo irá diretamente no seu componente DateInput
  // O DatePicker já deve ter um estilo interno para width: 100%
  // Mas se não tiver, você pode adicionar:
  > .react-datepicker-wrapper {
    flex: 1; // Faz o DatePicker preencher o espaço disponível no wrapper
  }

  > .react-datepicker__input-container {
    width: 100%; // Garante que o input ocupe todo o espaço disponível
  }

  // Especifica o estilo do input dentro do DateInputWrapper
  input {
    width: 100%; // Ocupa todo o espaço menos o ícone do calendário
    border: none; // Sem borda
    font-size: 16px; // Tamanho da fonte do input
    padding: 10px 10px 10px 30px; // Padding para que o texto não sobreponha o ícone
    border-radius: 10px; // Borda arredondada
    box-shadow: none; // Remove qualquer sombra interna que possa ter sido aplicada
  }
`;
export const SectorSelectWrapper = styled.div`
  position: relative;
  flex: 1; // Ocupa o espaço disponível
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9; // Cor de fundo para o wrapper
  border-radius: 10px; // Borda arredondada
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); // Sombra interna para dar profundidade
  margin: 10px 0; // Espaço acima e abaixo do select, ajuste conforme necessário

  &:after {
    content: '\25BC'; // Adiciona um ícone de seta para baixo
    position: absolute;
    right: 10px;
    pointer-events: none; // Impede que o ícone interfira no clique
    color: #333;
  }

  select {
    width: 100%; // Ocupa todo o espaço do wrapper
    border: none; // Sem borda
    background-color: transparent; // Fundo transparente para ver o estilo do wrapper
    padding: 10px 30px 10px 10px; // Padding para texto não sobrepor a seta
    border-radius: 10px; // Borda arredondada
    -webkit-appearance: none; // Remove a aparência padrão do select no Webkit/Blink
    -moz-appearance: none; // Remove a aparência padrão do select no Firefox
    appearance: none; // Remove a aparência padrão do select
    font-size: 16px; // Tamanho da fonte
    color: #333; // Cor do texto

    &:focus {
      outline: none; // Remove o contorno ao focar
      box-shadow: 0 0 0 2px #4d90fe; // Adiciona um contorno azul claro para acessibilidade
    }

    option {
      background-color: #f9f9f9; // Cor de fundo para as opções
      color: #333; // Cor do texto para as opções
    }
  }
`;
