// criar pagina de schedules
import React, { useEffect, useState } from 'react';
import { FiFrown, FiTrash2, FiEdit } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import Header from '../../../components/Header';
import AddDayOffModal from './Modal';
import Appointments from '../Appointments';

import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';
import { ca, tr } from 'date-fns/locale';
import { add, set } from 'date-fns';

interface DayOff {
  id: number;
  date: Date;
  description: string;
}

const DayOff: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [dayOffs, setDayOffs] = useState<DayOff[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [showEditModal, setShowEditModal] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDayOff(): Promise<void> {
      try {
        const response = await api.get('/provider/dayoff');
        setDayOffs(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os dayoffs',
          description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    }

    loadDayOff();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  function handleOpenModal(): void {
    setShowModal(true);
  }

  async function handleCloseModal(): Promise<void> {
    setShowModal(false);

    try {
      const response = await api.get('/provider/dayoff');
      setDayOffs(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os dados ',
        description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
      });
    }
  }

  async function handleAddDayOff(newDayOff: DayOff): Promise<void> {
    console.log(
      newDayOff.date +
        '  +++++  ' +
        newDayOff.description +
        '  +++++  ' +
        newDayOff.id,
    );
    try {
      console.log('PASSOU PELO TRY');
      await api.post('/provider/dayoff', newDayOff);
      console.log('  ++++' + newDayOff);
      addToast({
        type: 'success',
        title: 'Dia de folga adicionado!',
        description: 'O dia de folga foi adicionado com sucesso.',
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar dia de folga',
        description:
          'Ocorreu um erro ao adicionar o dia de folga, tente novamente.',
      });
    }
  }

  async function handleDeleteDayOff(id: number): Promise<void> {
    try {
      const confirmDelet = window.confirm(
        'Tem certeza que deseja deletar este dia de folga?',
      );

      if (!confirmDelet) {
        return;
      }
      await api.delete(`/provider/dayoff/${id}`);
      const response = await api.get('/provider/dayoff');
      const allDayOffs = response.data;
      if (isMounted) {
        setDayOffs(allDayOffs);
      }
      addToast({
        type: 'success',
        title: 'Dia de folga deletado!',
        description: 'O dia de folga foi deletado com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar dia de folga',
        description:
          'Ocorreu um erro ao deletar o dia de folga, tente novamente.',
      });
    }
  }

  return (
    <>
      <Header />
      <Container>
        <Content>
         

          {loading ? (
            <Spinner />
          ) : (
            <>
              <AddButton onClick={handleOpenModal}>
                <strong>Adicionar</strong>
              </AddButton>
              {dayOffs.length === 0 ? (
                <EmptyList>
                  <FiFrown />
                  <span>Nenhum dia de folga cadastrado</span>
                </EmptyList>
              ) : (
                <Scroll>
                  <table>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayOffs.map(dayOff => (
                        <tr key={dayOff.id}>
                          <td>{new Date(dayOff.date).toLocaleDateString()}</td>{' '}
                          <td>{dayOff.description}</td>
                          <td>
                            <IconButton
                              onClick={() => handleDeleteDayOff(dayOff.id)}
                            >
                              <FiTrash2 />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Scroll>
              )}
            </>
          )}
        </Content>
      </Container>
      <AddDayOffModal
        isOpen={showModal}
        onClose={handleCloseModal}
        handleAddDayOff={handleAddDayOff}
      />
    </>
  );
};

export default DayOff;
