import React, { ChangeEvent, useEffect, useState } from 'react';
import api from '../../services/api';

interface SectorInputProps {
  name: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SectorInput: React.FC<SectorInputProps> = ({ name, onChange }) => {
  const [sectors, setSectors] = useState<any[]>([]);

  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors/active');
        setSectors(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    loadSectors();
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <select name={name} onChange={onChange}>
        <option value="">SELECIONE UM LOCAL</option>
        {sectors.map(sector => (
          <option key={sector.id} value={sector.id}>
            {sector.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SectorInput;
