import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiArchive, FiFileText, FiMapPin } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

import { setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';

export interface NewDayOff {
  id: number;
  date: Date;
  description: string;
}

interface AddDayOffModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddDayOff: (newDayOff: NewDayOff) => Promise<void>;
}

const today = new Date();
const todayStartOfDay = setHours(
  setMinutes(setSeconds(setMilliseconds(today, 0), 0), 0),
  0,
);

const AddDayOffModal: React.FC<AddDayOffModalProps> = ({
  isOpen,
  onClose,
  handleAddDayOff,
}) => {
  const formRef = useRef<FormHandles>(null);
  async function handleSubmit(data: NewDayOff): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        date: Yup.date()
          .min(todayStartOfDay, 'A data não pode ser anterior a hoje')
          .required('Data obrigatória'),

        description: Yup.string().required('Descrição obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // console.log('Valores HANDLESUBMIT       &&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log

      await handleAddDayOff(data);
      onClose();
    } catch (err) {
      // console.log('Valores HANDLESUBMIT&&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }
  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Adicionar DayOff</h1>
                <Input
                  name="date"
                  icon={FiArchive}
                  type="date"
                  placeholder="Data"
                />
                <Input
                  name="description"
                  icon={FiFileText}
                  type="text"
                  placeholder="Descrição"
                />
                <Button type="submit">Adicionar</Button>
                <Button type="button" onClick={onClose}>
                  Cancelar
                </Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};
export default AddDayOffModal;
