import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { FiCalendar, FiClock, FiMapPin } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { CountBadge } from './styles';
import { AiFillPhone, AiOutlineWhatsApp } from 'react-icons/ai';
import Accordion from './Accordion';
import {
  holidays,
  libraryVacation,
  mumpsMigration,
} from '../../../config/holiday';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Header from '../../../components/Header';

//import footer
import Footer from '../../../components/Footer';

import Message from './Message';
import ConfirmModal from './Modal';

import homeImage from '../../../assets/hero.png';
import homeImage2 from '../../../assets/image2.png';

import {
  AvailableTimes,
  AvailableTimesContainer,
  BoxContainer,
  BoxH2,
  Container,
  Content,
  MobileNavigation,
  Picker,
  PickerContainer,
  Subtitle,
  Times,
  Section,
  StyledSectorInput,
  FooterFixed,
  Section2,
  ContactButtonsContainer,
  WhatsAppButton,
  PhoneButton,
} from './styles';
import SectorInput from '../../components/SectorSelectInput';

interface AvailableTime {
  time: string;
  value: Date;
  available: boolean;
  appointmentCount: number;
  available_1: boolean;
  appointmentCount_1: number;
}

interface Faq {
  question: string;
  answer: string;
}
interface Config {
  id: number;
  image: string;
  image_red: string;
  local: string;
  abreviation: string;
  phone: string;
  whatsapp: string;
  email: string;
  andress: string;
  imageFile?: File; // Campo opcional para o arquivo da imagem
  imageRedFile?: File;
}

const Dashboard: React.FC = () => {
  const [selectedSector, setSelectedSector] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState({
    time: new Date(),
    parsedTime: '',
  });
  const [hourIsSelected, setHourIsSelected] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [faq, setFaq] = useState<Faq[]>([]);

  const history = useHistory();

  const { addToast } = useToast();

  const { user } = useAuth();
  const [configs, setConfigs] = useState<Config[]>([]);
  const [
    settingsPersonalized,
    setSettingsPersonalized,
  ] = useState<Config | null>(null);

  useEffect(() => {
    async function loadConfigs() {
      try {
        const response = await api.get('/config');
        setConfigs(response.data);

        const configToEdit = response.data.find(
          (config: Config) => config.id === 1,
        );
        if (configToEdit) {
          setSettingsPersonalized(configToEdit);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Dados de personalização nao carregados',
          description: 'Dados de personalização nao carregados',
        });
      }
    }
    loadConfigs();
  }, [addToast]);

  useEffect(() => {
    async function loadFaq(): Promise<void> {
      const response = await api.get('faq');
      const faq: Faq[] = response.data.map((faq: Faq) => ({
        ...faq,
      }));
      setFaq(faq);
    }
    loadFaq();
  }, []);
  // console.log('Faq' + faq);

  useEffect(() => {
    async function loadSubjects() {
      try {
        const response = await api.get(`/subjects/${selectedSector}`);
        setSubjects(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    if (selectedSector) {
      loadSubjects();
    }
  }, [selectedSector]);

  //https://chat.openai.com/?model=text-davinci-002-render-sha

  useEffect(() => {
    if (user && (user.isProvider === 1 || user.isProvider === 2)) {
      history.push('/provider/appointments');
    }
  }, [user, history]);

  useEffect(() => {
    setSelectedHour({
      time: new Date(),
      parsedTime: '',
    });
    setHourIsSelected(false);

    async function loadAvailableTimes(): Promise<void> {
      const response = await api.get('availables', {
        params: {
          date: selectedDate.getTime(),
          sector_id: selectedSector,
        },
      });
      // console.log('DATA SELECIONADA', selectedDate.getTime());

      const available: AvailableTime[] = response.data.map(
        (time: AvailableTime) => ({
          ...time,
          available_1: time.appointmentCount > 1,
          appointmentCount_1: time.appointmentCount - 1,
        }),
      );
      console.log('Dados enviados', available);

      setAvailableTimes(available);
    }

    loadAvailableTimes();
  }, [selectedDate, selectedSector]);

  function handleSelectHour(time: Date): void {
    const parsedDate = format(
      parseISO(String(time)),
      "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
      {
        locale: pt,
      },
    );

    const newSelectedHour = {
      time,
      parsedTime: parsedDate,
    };

    setSelectedHour(newSelectedHour);
    handleToggleModal();
  }

  function handleToggleModal(): void {
    if (selectedHour.time) {
      setIsOpen(!isOpen);
    } else {
      addToast({
        type: 'info',
        title: 'Agendamento',
        description: 'Por favor, selecione uma hora para agendamento.',
      });
    }
  }

  function handleSelectSector(e: React.ChangeEvent<HTMLSelectElement>): void {
    setSelectedSector(Number(e.target.value));

    setSelectedHour({
      time: new Date(),
      parsedTime: '',
    });
    setHourIsSelected(false);
  }

  async function handleAddAppointment(
    sector: number,
    date: Date,
    subject: string,
  ): Promise<void> {
    try {
      const schema = Yup.string()
        .min(6, 'Assunto deve ter pelo menos 6 caracteres')
        .required('Assunto obrigatório');

      await schema.validate(subject, {
        abortEarly: false,
      });

      const response = await api.post('appointments', {
        sector_id: sector,
        date,
        subject,
      });

      const parsedDate = format(
        parseISO(String(date)),
        "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
        {
          locale: pt,
        },
      );

      addToast({
        type: 'success',
        title: 'Agendamento realizado com sucesso!',
        description: `Seu agendamento para o dia ${parsedDate} foi realizado com sucesso!`,
      });

      setIsOpen(!isOpen);

      history.push('/user/my_appointments');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        addToast({
          type: 'error',
          title: 'Erro no agendamento1',
          description: errors.undefined,
        });

        return;
      }

      const error = (err as { response: { data: any } }).response?.data;

      addToast({
        type: 'error',
        title: 'Erro no agendamento -',
        description: error?.message
          ? error?.message
          : 'Ocorreu um erro ao realizar o agendamento, por favor tente novamente.',
      });
    }
  }

  return (
    <Container>
      {selectedSector !== null && (
        <ConfirmModal
          isOpen={isOpen}
          time={selectedHour}
          sector={selectedSector}
          toggleModalVisible={handleToggleModal}
          handleAddAppointment={handleAddAppointment}
        />
      )}
      <Header />

      <Content>
        <MobileNavigation>
          <p>
            Ir para <Link to="my_appointments">MEUS AGENDAMENTOS</Link>
          </p>
        </MobileNavigation>

        <AvailableTimesContainer>
          <Section id="hero">
            <div className="background">
              <img src={homeImage} alt="" />
            </div>
            <div className="content">
              <div className="title">
                <h1>AGENDAMENTO CRO Ceará</h1>
                {/* <p>presencial</p> */}
              </div>
              <div className="search">
                <div className="container">
                  <label htmlFor="">
                    <FiMapPin size={25} color="#990000" />
                    Local
                  </label>

                  <StyledSectorInput>
                    <SectorInput
                      name="sectorSelect"
                      onChange={handleSelectSector}
                    />
                  </StyledSectorInput>
                </div>

                <div className="container">
                  <label htmlFor="">
                    {' '}
                    <FiCalendar size={25} color="#990000" />
                    Data
                  </label>
                  <StyledSectorInput>
                    <PickerContainer>
                      <Picker
                        locale={pt}
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={selectedDate}
                        excludeDates={
                          selectedSector === 1
                            ? holidays.concat(mumpsMigration)
                            : holidays.concat(libraryVacation)
                        }
                        onChange={(date: Date) => setSelectedDate(date)}
                      />
                    </PickerContainer>
                  </StyledSectorInput>
                </div>
                {/* <button>Explore Now</button> */}
              </div>
              <ContactButtonsContainer>
                <WhatsAppButton href="https://wa.me/${settingsPersonalized?.whatsapp}">
                  <AiOutlineWhatsApp size={20} />
                  {settingsPersonalized?.whatsapp}
                </WhatsAppButton>
                <PhoneButton href="https://wa.me/${settingsPersonalized?.phone}">
                  <AiFillPhone size={15} />
                  {settingsPersonalized?.phone}
                </PhoneButton>
              </ContactButtonsContainer>
            </div>
          </Section>

          {/* ------------------------------------------------------------- */}
          <BoxContainer>
            <BoxH2>
              <FiClock size={25} />
              <strong> Selecione um Horário</strong>
            </BoxH2>
          </BoxContainer>
          <Times columns={4}>
            {availableTimes.map(availableTime => (
              <AvailableTimes
                enabled={availableTime.available}
                key={availableTime.time}
                // onClick={() => handleSelectHour(availableTime.value)}
                className={
                  selectedHour.parsedTime.includes(`${availableTime.time}`)
                    ? 'selected'
                    : ''
                }
                onClick={() => handleSelectHour(availableTime.value)} // Chama handleSelectHour para selecionar o horário e abrir o modal
              >
                <span>{availableTime.time}</span>

                <>
                  {availableTime.appointmentCount > 0 && (
                    <CountBadge>{availableTime.appointmentCount}</CountBadge>
                  )}
                </>
              </AvailableTimes>
            ))}
          </Times>

          <Subtitle>
            <div>
              <div className="first-square" />
              Horários Disponíveis
            </div>
            <div>
              <div className="second-square" />
              Horários Indisponíveis
            </div>
            <div>
              <div className="third-circle" />
              Atendimentos Disponíveis
            </div>
          </Subtitle>

          {/* ------------------------------------------------------- */}
          <Section2>
            <div className="background">
              <img src={homeImage2} alt="" />
            </div>
            <div className="content">
              <div className="title">
                <h1>Perguntas Frequentes</h1>{' '}
              </div>
            </div>
          </Section2>
          <Accordion
            faqs={faq.map(faqItem => ({
              question: faqItem.question,
              answer: faqItem.answer,
            }))}
          />
        </AvailableTimesContainer>
      </Content>
      {/* ------------------------------------------------ */}
      <FooterFixed>
        <Footer />
      </FooterFixed>
    </Container>
  );
};

export default Dashboard;
