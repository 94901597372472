import React, { useEffect, useState } from 'react';
import { FiEdit, FiFrown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Header from '../../../components/Header';
import AddSubjectModal, { NewSubjectData } from './Modal';
import EditSubjectModal, { EditSubjectData } from './Modal/EditSubjectModal';
import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

interface SubjectData {
  id: number;
  title: string;
  description: string;
  sector: {
    id: number;
    title: string;
  };
  isActive: number;
}

const Subjects: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [subjects, setSubjects] = useState<SubjectData[]>([]);
  const [isMounted, setIsMounted] = useState(true);
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [
    selectedSubject,
    setSelectedSubject,
  ] = useState<EditSubjectData | null>(null);
  const [sectors, setSectors] = useState<any[]>([]); // Estado para armazenar os setores
  // console.log("SETORES ----------------\n", JSON.stringify(sectors, null, 2));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors'); // Certifique-se de que esta rota está correta
        setSectors(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    if (showModal || selectedSubject) {
      // Verifica se o modal está aberto antes de carregar os setores
      loadSectors();
    }
  }, [showModal, selectedSubject]);

  function handleOpenEditModal(subject: SubjectData): void {
    const editSubject: EditSubjectData = {
      id: subject.id,
      title: subject.title,
      description: subject.description,
      isActive: subject.isActive,
      sector_id: subject.sector.id,
    };
    setSelectedSubject(editSubject);
    setShowEditModal(true);
  }

  function handleCloseEditModal(): void {
    setShowEditModal(false);
    setSelectedSubject(null);
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  async function handleCloseModal() {
    setShowModal(false);

    try {
      const response = await api.get('/subjects');
      setSubjects(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os dados',
        description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
      });
    }
  }

  useEffect(() => {
    if (user.isProvider === 0) {
      history.push('/user/dashboard');
    }
  }, [user.isProvider, history]);

  useEffect(() => {
    async function loadSubjects(): Promise<void> {
      try {
        const response = await api.get('/subjects');
        if (isMounted) {
          setSubjects(response.data);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os assuntos',
          description:
            'Ocorreu um erro ao carregar os assuntos, tente novamente.',
        });
      } finally {
        setLoading(false); // Defina loading como false após a chamada da API
      }
    }
    loadSubjects();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  async function handleAddSubject(data: NewSubjectData): Promise<void> {
    try {
      await api.post('/subjects', data);
      addToast({
        type: 'success',
        title: 'Assunto cadastrado com sucesso!',
      });
      const response = await api.get('/subjects');
      setSubjects(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: '',
        description: 'Ocorreu um erro ao cadastrar o assunto, tente novamente.',
      });
    }
  }

  async function handleEditSubject(data: EditSubjectData): Promise<void> {
    try {
      await api.put(`/subjects/${data.id}`, data);
      addToast({
        type: 'success',
        title: 'Assunto editado com sucesso!',
      });
      setShowEditModal(false);
      setSelectedSubject(null);
      const response = await api.get('/subjects');
      setSubjects(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao editar o assunto',
        description: 'Ocorreu um erro ao editar o assunto, tente novamente.',
      });
    }
  }

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Content>
            <AddButton onClick={handleOpenModal}>Adicionar </AddButton>
            {subjects.length >= 1 ? (
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>Setor</th>
                      <th>Ativo</th>
                      <th>Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map(subject => (
                      <tr key={subject.id}>
                        <td>{subject.title}</td>
                        <td>{subject.sector.title}</td>
                        <td>{subject.isActive === 1 ? 'Sim' : 'Não'}</td>
                        <td>
                          <IconButton
                            onClick={() => handleOpenEditModal(subject)}
                          >
                            <FiEdit />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            ) : (
              <EmptyList>
                <FiFrown size={30} />
                <span>Nenhum assunto cadastrado</span>
              </EmptyList>
            )}
          </Content>
        )}
      </Container>

      {showModal && (
        <AddSubjectModal
          isOpen={showModal}
          onClose={handleCloseModal}
          handleAddSubject={handleAddSubject}
          sectors={sectors} // Passa os setores para o modal
          // como ver as propiedade do sector  . Ex sector.name
        />
      )}

      {selectedSubject && (
        <EditSubjectModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          handleEditSubject={handleEditSubject}
          subject={selectedSubject}
          sectors={sectors}
        />
      )}
    </>
  );
};

export default Subjects;
