import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
// import SectorInput from './SectorInput'; // Atualize o caminho para a localização correta do SectorInput
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';
import SectorInput from '../../Schedules/Modal/SectorInput';

// Interface para os dados de cadastro de usuário
export interface NewUserData {
  id: string;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  sector_id: number;
  isProvider: number;
}

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddUser: (newUser: NewUserData) => Promise<void>;
  sectors: any[];
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  isOpen,
  onClose,
  handleAddUser,
  sectors,
}) => {
  const formRef = useRef<FormHandles>(null);
  // const [selectedAgendamento, setSelectedAgendamento] = useState('');

  async function handleSubmit(data: NewUserData): Promise<void> {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password')],
          'Confirmação incorreta',
        ),
        sector_id: Yup.string().required('Selecione um setor'), // Updated validation for the sector_id
      });
      console.log('Esou aqui');
      await schema.validate(data, {
        abortEarly: false,
      });
      console.log('Esou aqui  2');

      await handleAddUser(data);
      onClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      // Lógica para exibir mensagens de erro no toast caso necessário
      // quero exibir uma mensagem de erro
    }
  }

  // const agendamentoOptions = [
  //   { value: '1', label: 'Atendimento-Sede-SE' },
  //   { value: '2', label: 'Outros' },

  // ];

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <h2>Inserir Nova Conta</h2>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="name" icon={FiUser} placeholder="Nome" />
                <Input name="email" icon={FiMail} placeholder="E-mail" />
                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Senha"
                />
                <Input
                  name="password_confirmation"
                  icon={FiLock}
                  type="password"
                  placeholder="Confirmar senha"
                />

                {/* <Input
                  name="sector_id"
                  icon={FiLock}
                  type="sector_id"
                  placeholder="Confirmar senha"
                />
                Using the SelectInput component */}

                <SectorInput name="sector_id" sectors={sectors} />

                <Button type="submit">Cadastrar</Button>
                <Button onClick={onClose}>Cancelar</Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default AddUserModal;
