import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
interface NavigationProps {
  isMenuOpen: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: auto;
  padding: 10px 20px;
  background: #990000;

  img {
    max-height: 40px;
  }

  @media (max-width: 650px) {
    flex-direction: row; // Mudar a direção para linha para telas pequenas
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  @media (min-width: 651px) {
    > svg {
      display: none;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #990000;

  @media (max-width: 650px) {
    justify-content: flex-end;
  }
`;
export const Navigation = styled.div<NavigationProps>`
  display: flex;
  align-items: center;
  margin-left: 30px;
  width: 100%;
  height: auto; // Definir a altura para 'auto'

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #990000;
    display: ${props => (props.isMenuOpen ? 'flex' : 'none')};
    max-height: ${props => (props.isMenuOpen ? '500px' : '0')};
    overflow: hidden;
    height: 100vh; // Ajuste para a altura total da viewport
    overflow-y: auto; // Permite a rolagem se necessário
    transition: transform 0.3s ease-in-out;
    transform: ${props =>
      props.isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'};
    z-index: 1500; // Ajustar conforme necessário
  }
`;

export const Nav = styled(NavLink)`
  display: inline-block;
  padding: 6px 12px;
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  margin-right: 12px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  font-family: 'Your Dashboard Font', sans-serif;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #800000;
  }

  &.active {
    background-color: #800000;
    border-color: #fff;
  }
  @media (max-width: 650px) {
    font-size: 16px; // Diminuir o tamanho da fonte para dispositivos móveis
    padding: 4px 8px; // Diminuir o padding para dispositivos móveis
    &:nth-child(-n+3) {  // Por exemplo, ocultar os três primeiros itens
      display: none;
  }
`;

// Estilize o submenu aqui
export const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #990000;
  z-index: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 150px;

  ${Nav} {
    margin-right: 0; /* Remove a margem direita para que os itens do submenu ocupem toda a largura */
    font-size: 16px; /* Reduza o tamanho da fonte para os itens do submenu */
  }
`;

export const MenuIcon = styled.div`
  display: none; // Esconder o MenuIcon por padrão

  @media (max-width: 650px) {
    display: block; // Mostrar o MenuIcon quando a largura da tela for <= 650px

    color: #fff;
    display: flex;
    position: absolute;
    right: 100px;

    cursor: pointer;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1; // Garantir que o UserInfo sempre apareça na frente do MenuIcon

  h2 {
    font-size: 15px;
    margin-right: 30px;
    color: #fff;

    @media (max-width: 650px) {
      display: none; // Esconder o texto quando a largura da tela é 650px ou menor
    }
  }

  button {
    font-size: 15px;
    background: 0;
    border: none;
    color: #fff;
    transition: color 0.2s;
    font-family: 'Your Dashboard Font', sans-serif;

    &:hover {
      color: #999;
    }

    @media (max-width: 650px) {
      right: 20px;

      position: relative; // Necessário para o z-index funcionar
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px; // Adicionar um espaçamento à margem direita
    }

    // Supondo que o ícone de logout esteja dentro de um span
    span {
      display: block;

      @media (max-width: 650px) {
        display: none; // Esconder o texto quando a largura da tela é 650px ou menor
      }
    }
  }
`;
