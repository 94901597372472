import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #f0f0f0;
    color: #333333;
    -webkit-font-smoothing: antialised;
  }

/*
  html, body, #root {
    height: 100vh;
  }
*/
  body, input, button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
    background-color: #F17886;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #990000;
  }
  
`;
