// criar pagina de schedules
import React, { useEffect, useState } from 'react';
import { FiFrown, FiTrash2 } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import Header from '../../../components/Header';
import AddSchedulesModal, { NewSchedulesData } from './Modal';
// import EditSchedulesModal, { EditSchedulesData } from './Modal/EditSchedulesModal';
import {
  AddButton,
  Container,
  Content,
  EmptyList,
  IconButton,
  Scroll,
} from './styles';

interface SchedulesData {
  id: number;
  hour: string;
  appointmentQuantity: number;
  exceptionDays: number[];

  sector: {
    id: number;
    title: string;
  };
}

const Schedules: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [schedules, setSchedules] = useState<SchedulesData[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const daysOfWeek = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  // const [showEditModal, setShowEditModal] = useState(false);
  // const [selectedSchedules, setSelectedSchedules] = useState<EditSchedulesData | null>(null);
  const [sectors, setSectors] = useState<any[]>([]); // Estado para armazenar os setores
  const [loading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState<NewSchedulesData>({
    id: 0,
    hour: '',
    appointmentQuantity: 0,
    sector_id: 0,
    exceptionDays: [],
  });
  // function handleOpenEditModal(schedule: SchedulesData): void {
  //     const editedSchedules: EditSchedulesData = {
  //         id: schedule.id,

  //         hour: schedule.hour,
  //         appointmentQuantity: schedule.appointmentQuantity,
  //         sector: schedule.sector,

  //     };
  //     setSelectedSchedules(editedSchedules);
  //     setShowEditModal(true);
  // }

  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors'); // Certifique-se de que esta rota está correta
        setSectors(response.data);
        // console.log('Valores em sectors:USEEFFECT', response.data);
      } catch (err) {
        console.error(err);
      }
    }

    if (showModal) {
      // Verifica se o modal está aberto antes de carregar os setores
      loadSectors();
    }
  }, [showModal]);

  // // Função para fechar o modal de edição
  // function handleCloseEditModal(): void {

  //     setShowEditModal(false);
  //     setSelectedSchedules(null);
  // }

  // Função para abrir o modal
  function handleOpenModal() {
    setShowModal(true);
  }

  // função para fechar o modal

  async function handleCloseModal(): Promise<void> {
    setShowModal(false);
    try {
      const response = await api.get('/attendance_hours');
      setSchedules(response.data);
    } catch (error) {
      //console.log('Erro ao buscar os horarioes:', error);
      addToast({
        type: 'error',
        title: 'Erro ao buscar horarioes',
        description:
          'Ocorreu um erro ao buscar os horarioes. Por favor, tente novamente mais tarde.',
      });
    }
  }

  // Redirecionamento para outra página caso o usuário não seja um provedor

  // Redirecionamento para outra página caso o usuário não seja um provedor
  useEffect(() => {
    if (user.isProvider === 0) {
      history.push('/user/dashboard');
    }
  }, [user.isProvider, history]);

  // buscar horarioes
  useEffect(() => {
    async function loadSchedules(): Promise<void> {
      try {
        const response = await api.get('/attendance_hours');
        const providerSchedules = response.data;

        if (isMounted) {
          setSchedules(providerSchedules);
        }
      } catch (error) {
        //console.log('Erro ao buscar os horarioes:', error);
        addToast({
          type: 'error',
          title: 'Erro ao buscar horarios',
          description:
            'Ocorreu um erro ao buscar os horarioes. Por favor, tente novamente mais tarde.',
        });
      } finally {
        setLoading(false); // Defina loading como false após a chamada da API
      }
    }

    loadSchedules();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  // Função para cadastrar um novo horario
  async function handleAddSchedules(
    newSchedules: NewSchedulesData,
  ): Promise<void> {
    try {
      await api.post('/attendance_hours', newSchedules);

      addToast({
        type: 'success',
        title: 'Cadastro realizado!',
        description: 'Novo horario cadastrado com sucesso!' + newSchedules,
      });
      setShowModal(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          'Ocorreu um erro ao cadastrar o novo horario. Por favor, tente novamente.',
      });
    }
  }

  // Função para editar um horario
  // async function handleEditSchedules(updateSchedules: EditSchedulesData): Promise<void> {
  //     console.log(updateSchedules.id);
  //     try {
  //         await api.put(`/attendance_hours/${updateSchedules.id}`, updateSchedules);

  //         addToast({
  //             type: 'success',
  //             title: 'Atualização realizada!',
  //             description: 'Setor atualizado com sucesso!',
  //         });
  //         setShowEditModal(false);
  //         setSelectedSchedules(null);

  //         const response = await api.get('/attendance_hours');
  //         const allSchedules = response.data
  //         setSchedules(allSchedules);

  //     } catch (error) {
  //         addToast({
  //             type: 'error',
  //             title: 'Erro na atualização',
  //             description: 'Ocorreu um erro ao atualizar o horario. Por favor, tente novamente.',
  //         });
  //     }

  // }

  // função para deletar um

  async function handleDeleteSchedules(id: number): Promise<void> {
    try {
      const confirmDelete = window.confirm(
        'Tem certeza que deseja deletar este horario?',
      );
      if (!confirmDelete) {
        return;
      }
      await api.delete(`/attendance_hours/${id}`);
      const response = await api.get('/attendance_hours');
      const allSchedules = response.data;
      if (isMounted) {
        setSchedules(allSchedules);
      }
      addToast({
        type: 'success',
        title: 'Setor deletado!',
        description: 'O horario foi deletado com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar horario',
      });
    }
  }

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Content>
            <AddButton onClick={handleOpenModal}>
              <strong>Adicionar</strong>
            </AddButton>

            {schedules.length >= 1 ? (
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      <th>Setor</th>
                      <th>Horas</th>
                      <th>Atendimentos</th>
                      <th>Dias de exceção</th>
                      <th>Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schedules.map(schedule => (
                      <tr key={schedule.id}>
                        <td>{schedule.sector.title}</td>
                        <td>{schedule.hour}</td>
                        <td>{schedule.appointmentQuantity}</td>

                        
                          <td>
                            {schedule.exceptionDays &&
                              schedule.exceptionDays.map(day => (
                                <div key={day}>{daysOfWeek[day]}</div>
                              ))}
                          </td>
                        
                        {/* Exiba os dias de exceção separados por vírgula */}
                        <td>
                          {' '}
                          <IconButton
                            onClick={() => handleDeleteSchedules(schedule.id)}
                          >
                            <FiTrash2 size={20} />
                          </IconButton>
                        </td>
                        <td>
                          {/* <IconButton onClick={() => handleOpenEditModal(schedule)}>
                                                    <FiEdit size={20} />
                                                </IconButton> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            ) : (
              <EmptyList>
                <FiFrown size={50} color="#333" />
                <h2>Oooops... Não existem Horários cadastrados.</h2>
              </EmptyList>
            )}
          </Content>
        )}
      </Container>
      {/* {console.log('Valores em sectors:PRINCIPAL', sectors)} */}
      {showModal && (
        <AddSchedulesModal
          isOpen={showModal}
          onClose={handleCloseModal}
          handleAddSchedules={handleAddSchedules}
          sectors={sectors}
          setFormattedData={setFormattedData} // Pass the "setFormattedData" function as a prop
        />
      )}

      {/* {selectedSchedules && (
                <EditSchedulesModal
                    isOpen={showEditModal}
                    onClose={handleCloseEditModal}

                    handleEditSchedules={handleEditSchedules}
                    sector={selectedSchedules} // Adiciona a propriedade sector ao objeto

                />
            )} */}
    </>
  );
};

export default Schedules;
